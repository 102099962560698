import React from 'react';
import styles from './Posts.module.css'
import { useQuery } from '@tanstack/react-query';
import backendRequest from '../../axios';
import LoadingSpinner from '../Spinner';
import moment from 'moment';

function Posts() {
    const { isLoading, error, data } = useQuery(["postsAll"], () => {
        return backendRequest.get("/posts").then(res => {
            return res.data.filter(post => post.published === 1).sort((a, b) => new moment(a.created_date).format('YYYYMMDD') - new moment(b.created_date).format('YYYYMMDD')).reverse();
        });
    });

    return (
        <>
            <div className={styles.title}>Список новин:</div>
            <div className={styles.content}>
                {
                    isLoading
                    ? <LoadingSpinner/>
                    : error
                    ? "Щось пішло не так!"
                    :<>
                    {
                        data.map(post => (<div key={post.id} className={styles.post}>
                            <a className={styles.post_link} href={"/posts/" + post.id}>
                                <div className={styles.date}>{moment(post.created_date).format("DD-MM-YYYY")}</div>
                                <div className={styles.post_title}>{post.title}</div>
                            </a>
                        </div>))
                    }
                    </>
                }
            </div>
        </>
    );
}

export default Posts