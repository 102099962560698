import React, { useState } from "react";
import styles from './EducationalPrograms.module.css'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

function EducationalPrograms({props}){

    const data=[
        {id:0,title:'"Прикладна математика та інформатика"',img:'osvit_pm_new.jpg'},
        {id:1,title:'"Фінансовий інжинирінг"',img:'osvit_pm_new.jpg',img:'osvit_fi_new.jpg'},
    ]

    const [state,setState]=useState(false)
    const defaultState=data.map(el=>{return false})
    const [program,setProgram]=useState(defaultState)

    const click=(id)=>{
        setState(true)
        const newProgram=[...program]
        newProgram[id]=true
        setProgram(newProgram)
    }

    const back=()=>{
        const fal=data.map(el=>{return false})
        setProgram(fal)
        setState(false)
    }

    return(
        <>
            {/* <div className={styles.title}>Представляємо Вам освітні програми спеціальностей<br/>"Прикладна математика та інформатика" та "Фінансовий інжинирінг"</div>
            <div className={styles.images}>
                <img className={state[0] ? styles.active : styles.img} onClick={()=>setState([!state[0],false])} src="static/img/osvit_pm_new.jpg"/>
                <img className={state[1] ? styles.active : styles.img} onClick={()=>setState([false,!state[1]])} src="static/img/osvit_fi_new.jpg"/>
            </div> */}
            {state ? 
            <>
                <div className={styles.title}>
                    Представляємо Вам освітню програму спеціальності<br/>
                    {program.map((el,index)=>{
                        if(el) return data[index].title
                    })}
                </div>
                <div className={styles.back} onClick={()=>back()}> <ArrowBackRoundedIcon/> Повернутися назад </div>
                <div className={styles.images}>
                    {data.map((prog,index)=>{
                        if(program[index]) return <img alt={prog.img} key={index} className={styles.active} src={`static/img/${prog.img}`}/>
                    })}
                    
                </div>
            </>
            :
            <>
                <div className={styles.title}>Представляємо Вам освітні програми спеціальностей<br/>"Прикладна математика та інформатика" та "Фінансовий інжинирінг"</div>
                <div className={styles.sub_title}>Для перегляду освітньої програми натисність на відповідну картку</div>
                <div className={styles.main}>
                    {data.map((prog,index)=>{
                        return <div key={index} className={styles.dropLink} onClick={()=>click(index)}>Освітня програма спеціальності {prog.title}</div>
                    })}
                </div>
            </>}
            
            
        </>
        
    )
}

export default EducationalPrograms
