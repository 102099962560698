import React, { useEffect } from 'react';
import styles from "../AdminMain.module.css";
import { useQuery } from '@tanstack/react-query';
import backendRequest from '../../../../axios';
import LoadingSpinner from '../../../Spinner';
import { Link } from 'react-router-dom';

function AdminGalleries() {
    const { isLoading, error, data} = useQuery(["galleries"], () =>
        backendRequest.get("/galleries").then((res) => {
            return res.data
        }));

    useEffect(() =>{
        data && data.forEach((obj, id)=> {
            backendRequest.get("/download/" + obj.title_image, {responseType:"blob"})
                .then((res) => {
                    const img = document.getElementById("photo_" + id);
                    var fr = new FileReader();
                    fr.onload = () => {
                        img.src = fr.result;
                    }
                    fr.readAsDataURL(res.data);
                });
        })
    }, [data]);
    
    return (
        <div className={styles.container + ' ' + styles.flex_container}>
            {
                error
                ? error.response.data
                : isLoading
                ? <LoadingSpinner/>
                : data.map((item,id) => <Link to={"/admin/galleries/" + item.id} key={item.id} className={styles.gallery_card} state={{id: item.id}} id={item.id}>
                    <img id={"photo_" + id} src=""/>
                    {item.title}
                </Link>)
            }
            <Link className={styles.gallery_link} to="new">
                <div className={styles.gallery_card + ' ' + styles.add_new_card}>
                    <img src="/static/img/add_new.png"/>
                    Додати нову галерею
                </div>
            </Link>
        </div>
        
    );
}

export default AdminGalleries;