import {Routes,Route} from "react-router-dom";
import React from 'react'
import ScrollToTop from './ScrollToTop';
import Main from './components/main/Main'
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Admin from "./components/admin/Admin";
import Login from "./components/login/Login";

function App() {
    const queryClient = new QueryClient();

    return (
          <QueryClientProvider client={queryClient}>
              <ScrollToTop/>
              <Routes>
                  <Route path ='admin/*' element={<Admin/>}/>
                  <Route path ='admin/login' element={<Login/>}/>
                  <Route path='/*' element={<Main/>}/>
              </Routes>
          </QueryClientProvider>
    );
}

export default App;
