import React from "react";
import styles from './Courses1.module.css'
import data from './file (51).json'
import {Routes,Route,Link} from "react-router-dom";




function Courses1(props){
    
    return(
        <>
        <div className={styles.title}>Карти курсів</div>
        <div className={styles.sub_title}>Для перегляду карти курсу натисність на відповідну картку</div>
            <div className={styles.main}>
                {data.map((map,index)=>{
                    return <Link key={index} to={`${map.id}`} className={styles.dropLink}>{map.name}</Link>
                })}
                
                {/* <div className={styles.coursemap_title}>Карти курсів бакалавріату 2022-2023 н.р.</div>
                {
                    data.map(el=><Course name={el.name} course={el.courses} icons={el.icons}/>)
                } */}

            </div>
        </>
        
    );
}

export default Courses1
