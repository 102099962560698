import React, { useEffect, useState } from 'react';
import styles from './Header.module.css'
import {Link} from "react-router-dom";
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import MailIcon from '@material-ui/icons/Mail';
import MenuElement from "../mobileHeader/MenuElement";
import backendRequest from '../../axios';

function Header(props) {
    const [galleries, setGalleries] = useState([{text:"Галереї тимчасово відсутні!", link:'/'}]);
    const info = [
        {link:'department',text:'Кафедра',arr:[{link:'department',text:'Про кафедру'},{link:'history',text:'Історія кафедри'},{link:'heads',text:'Завідувачі кафедрою'},{link:'graduates',text:'Відомі випускники'},{link:'magazine',text:'Науковий журнал'},{link:'labs',text:'Навчальні лабораторії'}]},
        {link:'staff/teachers',text:'Викладачі',arr:[]},
        {link:'/',text:'Галерея',arr:[{text:"Галереї тимчасово відсутні!", link:'/'}]},
        {link:'/',text:'Студентам',arr:[{link:'https://vns.lpnu.ua/my/', text:'Віртуальне навчальне середовище'}, {link:'https://student.lpnu.ua/students_schedule?departmentparent_abbrname_selective=%D0%86%D0%9C%D0%A4%D0%9D&semestrduration=1', text:'Розклад занять'}]},
        {link:'invitation',text:'Абітурієнтам',arr:[{link:'invitation',text:'Запрошуємо на навчання'},{link:'programs', text:'Освітні програми'},{link:'courses', text:'Карти курсів'},{link:'dual-education', text:'Дуальна освіта'},{link:'dual',text:'Подвійне дипломування'}, {link:'companies', text:'Місця роботи'}, {link:'excursions', text:'Екскурсії кафедрою!'}]},
    ];

    useEffect(() => {
        async function fetchGalleries() {
            let arr = [];
            const res = await backendRequest.get("/galleries");

            res.data && res.data.forEach(element => {
                arr.push({link:"galleries/" + element.id, text:element.title});
            });
            setGalleries(arr);
        }

        fetchGalleries();
    }, [])

    return (
            <>
                <div className={styles.info}>
                    <PhoneIcon className={styles.img}/>
                    <div>+38 (032) 258-23-68</div>
                    <BusinessIcon className={styles.img}/>
                    <div>79013, Львів 13, вул. Митрополита Андрея 5, IV навчальний корпус, кімната 213</div>
                    <MailIcon className={styles.img}/>
                    <div>pm.dept@lpnu.ua</div>
                </div>
                <hr/>

                <div className={styles.main_div}>
                    <div className={styles.menu}>
                        <Link to='/'> <img className={styles.logo} src={process.env.PUBLIC_URL + "/static/img/am_newlogo.png"} alt="Logo"/></Link>
                        {info.map((element)=>{
                            return(
                                <div key={element.text} className={styles.submenu}>
                                    <Link to={element.link} className={styles.link}>{element.text}</Link>
                                    {
                                        element.text == 'Галерея'
                                        ? <div className={styles.drop}>
                                            {galleries.map((el)=>
                                            <Link key={el.text} to={el.link} className={styles.dropLink}>{el.text}</Link>
                                            )}
                                        </div>
                                        : element.arr.length > 0 &&
                                        <div className={styles.drop}>
                                            {element.arr.map((el)=>
                                            <Link key={el.text} to={el.link} className={styles.dropLink}>{el.text}</Link>
                                            )}
                                        </div>
                                    }

                                </div>
                            )})}
                    </div>
                </div>
                <hr/>
            </>

    );}

export default Header;