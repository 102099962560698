import React, { useState } from "react";
import styles from "../AdminMain.module.css";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from "@tanstack/react-query";
import backendRequest from "../../../../axios";
import LoadingSpinner from "../../../Spinner";

function AdminTeacherDescKeysGrid(){
    const [errorMessage, setErrorMessage] = useState("Не вдалось додати параграф. Проблеми із сервером...");
    const [baddataDisplay, setBaddataDisplay] = useState("none");
    const [successDisplay, setSuccessDisplay] = useState("none");
    const [inputs, setInputs] = useState({
        title: ""
    })

    const handleDeleteParagraph = (event, itemId) =>{
        event.preventDefault();
    }
    
    const inputsHandle = (e) => {
        setInputs(prev => ({...prev, [e.target.id]: e.target.value}))
        setSuccessDisplay("none");
        setBaddataDisplay("none");
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        if ((!inputs.title && !inputs.title.trim()))
        {
            setBaddataDisplay("block");
            setErrorMessage("Назва параграфу не повинна бути пустою!");
            return;
        }
        const type = document.querySelector('input[name="desc_type"]:checked').value;
        const requestBody = {
            desc_title : inputs.title,
            desc_type : type
        }
        await backendRequest
                .post("/teachers/descKeys", requestBody)
                .then(res => {
                    setSuccessDisplay("block");
                    setBaddataDisplay("none");
                    refetch();
                })
                .catch(err => {
                    setBaddataDisplay("block");
                    setErrorMessage(err.response.data);
                });
        setInputs({title: ""});
        document.getElementById("title").value = "";
    }

    const { isLoading, error, data, refetch} = useQuery(["descKeys"], () =>
        backendRequest.get("/teachers/descKeys/all").then((res) => {
              return res.data
        }));

    return(
        <>
            {
                error
                ? "Щось пішло не так!"
                : isLoading
                ? <LoadingSpinner/>
                :
                <>
                    <div className={styles.dashboard}>
                        <button onClick={() => window.location.reload()} className={styles.edit}><EditIcon/>Редагувати викладачів</button>
                    </div>
                    <div className={styles.descKeys_main}>
                        <div id="descKeys" className={styles.content}>
                            <div className={styles.table_row + ' ' + styles.table_header} key="0">
                                <div className={styles.table_cell}>
                                    Назва параграфу
                                </div>
                                <div className={styles.table_cell}>
                                    Вид
                                </div>
                                <div className={styles.table_cell}>
                                    Видалити
                                </div>
                            </div>
                            {
                                data.map(item => (<div className={styles.table_row} key={item.id}>
                                    <div className={styles.table_cell}>
                                        {item.desc_title}
                                    </div>
                                    <div className={styles.table_cell}>
                                        {item.desc_type === "list" ? "Список" : item.desc_type === "text" ? "Текст" : "Блок \"значення-список\""}
                                    </div>
                                    <div className={styles.table_cell}>
                                        <button className={styles.delete_button} onClick={(event) => handleDeleteParagraph(event, item.id)}><CloseIcon/></button>
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                        <div className={styles.helper}>
                            <form className={styles.add_descKey_form}>
                                <label className={styles.form_title}> Додати новий параграф </label>
                                <input type="text" name="desc_title" id="title" placeholder="Назва нового параграфу" onChange={inputsHandle}></input>
                                {/*<input type="text" name="desc_title_eng" id="title_eng" placeholder="Назва нового параграфу (англійською)" onChange={inputsHandle}></input>*/}
                                <fieldset>
                                    <legend>Тип параграфу</legend>
                                    <div>
                                        <input type="radio" name="desc_type" value="text" id="text" defaultChecked/>
                                        <label htmlFor="text">Текстове поле</label>
                                    </div>
                                    <div>
                                        <input type="radio" name="desc_type" value="list" id="list"/>
                                        <label htmlFor="list">Список</label>
                                    </div>
                                    <div>
                                        <input type="radio" name="desc_type" value="doub-list" id="doub-list"/>
                                        <label htmlFor="doub-list">Блок "значення-список"</label>
                                    </div>
                                </fieldset>
                                <p className={styles.success} style={{display: successDisplay}}>Успішно додано!</p>
                                <p className={styles.warning} style={{display: baddataDisplay}}>{errorMessage}</p>
                                <button className={styles.submit_button} onClick={submitHandle}>Додати параграф</button>
                            </form>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default AdminTeacherDescKeysGrid