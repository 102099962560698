import React from "react";
import AdminHeader from "./header/AdminHeader"
import AdminSidebar from "./sidebar/AdminSidebar"
import AdminMain from "./main/AdminMain"

function Admin(props){
    return(
        <>
            <AdminHeader/>
            <AdminSidebar/>
            <AdminMain/>
        </>
    )
}

export default Admin