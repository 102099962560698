import React from "react";
import styles from "../AdminMain.module.css";
import {Link} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from "@tanstack/react-query";
import backendRequest from "../../../../axios";
import LoadingSpinner from "../../../Spinner";
import AdminTeacherDescKeys from "./AdminTeacherDescKeysGrid";
import moment from "moment";

function AdminTeachersGrid({handleEdit}){
    const { isLoading, error, data, refetch} = useQuery(["teachers"], () =>
        backendRequest.get("/teachers").then((res) => {
            return res.data
        }));

    const handlePublish = async (event, teacherId, toPublish) => {
        event.preventDefault();

        await backendRequest.put("teachers/" + teacherId, {published : toPublish ? 1 : 0});
        refetch();
    }

    return(
        <>
            {
                error
                ? error.response.data
                : isLoading
                ? <LoadingSpinner/>
                :
                <>
                    <div className={styles.dashboard}>
                        <Link className={styles.add_new} to='new' state={{isNew:true}}><AddIcon/>Додати викладача</Link>
                        <button className={styles.edit} onClick={(event) => handleEdit(event, <AdminTeacherDescKeys handleEdit={handleEdit}/>)}><EditIcon/>Редагувати параграфи</button>
                    </div>
                    <p className={styles.teacher_grid_title}> Опубліковані викладачі ({data.filter(teacher => teacher.published === 1).length})</p>
                    <div id="teachers" className={styles.content + ' ' + styles.teachers}>
                        <div className={styles.table_row + ' ' + styles.table_header} key="0">
                            <div className={styles.table_cell}>
                                ПІБ
                            </div>
                            <div className={styles.table_cell}>
                                Дата створення
                            </div>
                            <div className={styles.table_cell}>
                                Дата останнього оновлення 
                            </div>
                            <div className={styles.table_cell}>
                                Управління
                            </div>
                        </div>
                        {
                            data.filter(teacher => teacher.published === 1).map((teacher) => {
                                const PIB = teacher.full_name.toString().split(' ')
                                return <div className={styles.table_row} key={teacher.id}>
                                            <div className={styles.table_cell}>
                                                {PIB[0]} {PIB[1][0]}.{PIB[2][0]}.
                                            </div>
                                            <div className={styles.table_cell}>
                                                {moment(teacher.created_date).format("YYYY-MM-DD HH:mm:ss")}
                                            </div>
                                            <div className={styles.table_cell}>
                                                {moment(teacher.last_updated_date).format("YYYY-MM-DD HH:mm:ss")}
                                            </div>
                                            <div className={styles.table_cell + ' ' + styles.table_control_cell}>
                                                <Link to={"/admin/teachers/" + teacher.id}>Редагувати</Link>
                                                <button onClick={(event) => handlePublish(event, teacher.id, false)}>Вилучити</button>
                                            </div>
                                         </div>
                            })
                        }
                    </div>
                    {
                        data.filter(teacher => teacher.published === 0).length === 0 
                        ? <></>
                        : <>
                            <p className={styles.teacher_grid_title}> Приховані викладачі ({data.filter(teacher => teacher.published === 0).length})</p>
                            <div id="teachers" className={styles.content + ' ' + styles.teachers}>
                                <div className={styles.table_row + ' ' + styles.table_header} key="0">
                                    <div className={styles.table_cell}>
                                        ПІБ
                                    </div>
                                    <div className={styles.table_cell}>
                                        Дата створення
                                    </div>
                                    <div className={styles.table_cell}>
                                        Дата останнього оновлення 
                                    </div>
                                    <div className={styles.table_cell}>
                                        Управління
                                    </div>
                                </div>
                                {
                                    data.filter(teacher => teacher.published === 0).map((teacher) => {
                                        const PIB = teacher.full_name.toString().split(' ')
                                        return <div className={styles.table_row} key={teacher.id}>
                                                    <div className={styles.table_cell}>
                                                        {PIB[0]} {PIB[1][0]}.{PIB[2][0]}.
                                                    </div>
                                                    <div className={styles.table_cell}>
                                                        {moment(teacher.created_date).format("YYYY-MM-DD HH:mm:ss")}
                                                    </div>
                                                    <div className={styles.table_cell}>
                                                        {moment(teacher.last_updated_date).format("YYYY-MM-DD HH:mm:ss")}
                                                    </div>
                                                    <div className={styles.table_cell + ' ' + styles.table_control_cell}>
                                                        <Link to={"/admin/teachers/" + teacher.id}>Редагувати</Link>
                                                        <button onClick={(event) => handlePublish(event, teacher.id, true)}>Опублікувати</button>
                                                    </div>
                                                 </div>
                                    })
                                }
                            </div>
                        </>
                    }
                    
                </>
            }
        </>
    )
}

export default AdminTeachersGrid