import React from 'react';
import {Link} from "react-router-dom";
import {useState} from "react";
import styles from './MenuElement.module.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function MenuElement({props,onMenuClick}) {
    const [state,setState]=useState(false)

    function click(){
        return setState(!state)
    }

    return (
        <>
        <div key={props.id} className={styles.row}>
            <Link to={props.link} className={styles.link1} onClick={()=>onMenuClick(false)}>{props.text}</Link>
            {props.arr.length>0 && (state ? <ExpandLessIcon className={styles.expand} onClick={click}/> : <ExpandMoreIcon className={styles.expand} onClick={click}/>)}
            {
                props.arr.length>0 &&
                <div className={state ? styles.drop : styles.drop_close}>
                    {props.arr.map(el =>
                    <Link to={el.link} className={styles.dropLink} onClick={()=>{onMenuClick(false);setState(false)}}>{el.text}</Link>)}
                </div>

            }
        </div>
            
        </>
    );
}

export default MenuElement;