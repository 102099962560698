import React, { useEffect, useState } from 'react';
import styles from "../AdminMain.module.css";
import backendRequest from '../../../../axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AdminGallery() {
    const { state, pathname } = useLocation()
    const pathDelails = pathname.split('/');
    const pathId = pathDelails[pathDelails.length - 1];
    const galleryId = state ? state.id : pathId;
    const [photos, setPhotos] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [title, setTitle] = useState("");
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("Не вдалось виконати операцію. Проблеми із сервером...");
    const [baddataDisplay, setBaddataDisplay] = useState("none");
    const [successDisplay, setSuccessDisplay] = useState("none");

    useEffect(() => {
        async function fetch() {
            await backendRequest.get(`/galleries/${galleryId}`).then((res) => {
                setPhotos(res.data[0].images);
                setInitialData(res.data[0]);
                setTitle(res.data[0].title);
                return res.data[0];
            });
        }

        fetch();
    }, [])
    
    useEffect(() => {
        photos.forEach((obj, id)=> {
            if (typeof obj.name == 'string') {
                const img = document.getElementById("photo_" + id);
                var fr = new FileReader();
                fr.onload = () => {
                    img.src = fr.result;
                }
                fr.readAsDataURL(obj);
            }
            else
            backendRequest.get("/download/" + obj.path_name, {responseType:"blob"})
                .then((res) => {
                    const img = document.getElementById("photo_" + id);
                    var fr = new FileReader();
                    fr.onload = () => {
                        img.src = fr.result;
                    }
                    fr.readAsDataURL(res.data);
                });
        })
    }, [photos])

    const handleAddPhotos = (e) => {
        var files = e.target.files;

        setPhotos(prev => [...prev, ...files]);
    }

    const removePhoto = (event, id) => {
        event.preventDefault();
        setPhotos((prev) => prev.filter((obj, objId) => objId !== id));
    }
    
    const handleSubmit = async (id) => {
        const initialPhotoIds = initialData.images.map(img => img.id);
        const photoIds = photos.filter(img => typeof img.name !== 'string').map(img => img.id);
        if (photos.length == 0) {
            setBaddataDisplay("block");
            setErrorMessage("Галерея повинна містити мінімум одну фотографію!");
            return;
        }

        photos.forEach(async (photo, id) => {
            console.log(initialPhotoIds.indexOf(photo.id) === -1);
            const photoTitle = document.getElementById("photo_" + id + "_title").value;

            //Нова фотографія
            if (typeof photo.name == 'string') {
                console.log("new:" + id);
                var formData = new FormData();
                formData.append("file", photo);
                const fileName = await backendRequest.post("/upload", formData);
                await backendRequest.post("/attachments", {parent_content_id:initialData.content_id, title: photoTitle, path_name:fileName.data, type:"gallery-photo"})
            }
            //Оновлена фотографія
            else if (initialPhotoIds.indexOf(photo.id) > -1)
            {
                console.log("update:" + id);
                await backendRequest.put("/attachments/" + photo.id, {title: photoTitle, path_name:photo.path_name});
            }
        })

        //Видалена фотографія
        initialData.images.filter(img => photoIds.indexOf(img.id) === -1).forEach(async img =>
        {
            console.log("delete:" + img.id);
            await backendRequest.delete("/attachments/" + img.id);
        });

        setBaddataDisplay("none");
        setSuccessDisplay("block");
        setTimeout(() => navigate("/admin/galleries"), 1000);
    }

    const handleChangeTitle = (e) => {
        e.preventDefault();
        const newTitle = window.prompt("Введіть нову назву галереї:", title);
        
        if (!newTitle || !newTitle.trim()){
            return;
        }

        if (title.indexOf(newTitle) === -1)
        {
            backendRequest.put("/galleries/" + galleryId, {title: newTitle})
                .then(res => {
                    setTitle(newTitle);
                    return res.data;
                })
        }
    }

    return (
        <div className={styles.container}>
            <Link className={styles.return} to="/admin/galleries"><ArrowBackIcon/>Повернутись до списку галерей</Link>
            <div className={styles.gallery_title}>{title}<Edit onClick={handleChangeTitle}/></div>
            {
                <div className={styles.flex_container}>
                    {
                        photos.map((item, id) =>
                            <div key={item.id} className={styles.gallery_card}>
                                <img id={"photo_" + id} src=""/>
                                <textarea className={styles.gallery_new_photo_title} placeholder="Опис фото(необов'язково)" id={"photo_" + id + "_title"} defaultValue={item.title || ""}/>
                                <button className={styles.gallery_remove_photo} onClick={(event) =>{removePhoto(event, id)}}>Вилучити</button>
                            </div>
                        )
                    }
                    <div className={styles.gallery_card + ' ' + styles.add_new_card}>
                        <label className={styles.flex_container} htmlFor="photo-input">
                            <img src="/static/img/add_new.png"/>
                            <input style={{ display: "none" }} type="file" id="photo-input" name="" accept="image/png, image/jpeg" multiple="multiple" onChange={(e) => handleAddPhotos(e)} />
                            Додати фотографії
                        </label>
                    </div>
                </div> 
            }
            <div className={styles.flexbox_rows}>
                <p className={styles.form_success} style={{display: successDisplay}}>Успіх!</p>
                <p className={styles.form_warning} style={{display: baddataDisplay}}>{errorMessage}</p>
                <button className={styles.gallery_submit} onClick={handleSubmit}>Зберегти зміни</button>
            </div> 
        </div>
        
    );
}

export default AdminGallery;