import React,{useState} from "react";
import { useQuery } from "@tanstack/react-query";
import ModifyPost from "./ModifyPost";
import { useLocation } from 'react-router-dom';
import backendRequest from "../../../../axios";

function GetPost(props){

    const location=useLocation()

    const pathDelails = location.pathname.split('/');
    const postId = pathDelails[pathDelails.length - 1];
    
    const [post,setPost]=useState()
    const { isLoading, error, data} = useQuery(["posts"], () =>
        backendRequest.get("/posts/" + postId).then((res) => {
            setPost(res.data[0])
            setPost((prevPost) => ({
                ...prevPost,
                description: JSON.parse(prevPost.description)
                }))
                const uprgatedDesc=JSON.parse(res.data[0].description).map(el=>{
                  const mod=[]
                  if(el.title!==undefined) mod.push({obj:'title',arr:[]})
                  if(el.text!==undefined) mod.push({obj:'text',arr:[]})
                  if(el.list!==undefined) mod.push({obj:'list',arr:[]})
                  el.list!==undefined && el.list.map((elem,index)=>{
                      mod.map(modifiers=>{
                        if(modifiers.obj==='list'){
                          modifiers.arr.push({id:index,arr:[]})
                        }
                      })
                    })
                  return{...el,modifiers:mod} 
                })
                setPost(prev=>({...prev,description:uprgatedDesc}))
            return res.data
      }));
    return <ModifyPost props={post}/>
}

export default GetPost