import React, { useState } from 'react';
import styles from "./TeacherPage.module.css";
import parse from 'html-react-parser';

function TeacherPageList({ list, title }) {
    const obj = JSON.parse(list);

    return (
        <details id={title} open>
            <summary className={styles.list_block_title}>{title}</summary>
            {
                obj.map((text, id) => <p key={id}>{parse(text)}</p>)
            }
        </details>
    );
}

export default TeacherPageList;