import React from 'react';
import styles from './Welcome.module.css'
import {Link} from 'react-router-dom'
import {useState,useEffect} from 'react'

function Welcome(props) {

    const info=[
        {title:'Освітні програми',img:'osvit',link:'programs',text:'Тут Ви можете ознайомитися з освітніми програмами спеціалізацій "Прикладна математика та інформатика" і "Фінансовий інжинирінг", а саме: короткий зміст програми, можливе працевлаштування по закінченню програми та інше!'},
        {title:'Дуальна освіта',img:'softserve',link:'dual-education',text:'Дізнайтеся, як можна поєднювати навчання та роботу у ІТ-компанії на прикладі дуальної освіти разом з компанією SoftServe! '},
        {title:'Навчальна програма подвійних дипломів',img:'ES',link:'dual',text:'Студенти кафедри ПМ мають шанс навчатись в університетах Європи та отримати два дипломи магістра!'}
    ]

    const [isVisible, setIsVisible] = useState(true);

    const height=((window.innerHeight - 100)/3,631)/(window.innerWidth/765)

    useEffect(() => {
        const timeout = setTimeout(() => {
        setIsVisible(false);
    }, 1200);

    return () => clearTimeout(timeout);
  }, []);

    return (
        <>
            <div className={styles.main}>
            {/*<div style={isVisible ? {display:'flex',justifyContent:'center',width:'100%',height:'calc(100vh - 100px)'}:{transition:'0.3s ease-in-out'}}>
                <img className={isVisible ? styles.intro_img : styles.hidden_intro_img} src='fon.png' alt="fon"/>
            </div>*/}
            {/* <div className={styles.intro_wrapper}>
                <img className={styles.intro_img2} src='fon2.png' alt="fon"/>
            </div> */}
            {/* <div  style={isVisible ? {display:'flex',justifyContent:'center',width:'100%',height:'calc(100vh - 100px)'}:{transition:'0.3s ease-in-out'}}><img className={isVisible ? styles.img : styles.hidden} src='fon.png' alt="fon"/></div> */}
            <div style={isVisible ? {}:{height:`${height}px`}} className={isVisible ? styles.div : styles.div1}><img className={isVisible ? styles.img : styles.small} src='/static/img/intro_label_transparent.png'/></div>
            <div className={styles.window}>
                    <h2>Вас може зацікавити:</h2>
                    <div className={styles.items}>
                        {info.map((el, id)=>{
                            return(
                                <Link to={el.link} className={styles.item} key={id}>
                                        <div className={styles.card}>
                                            <p className={styles.title}>{el.title}</p>
                                            <img className={styles.small_img} src={`static/img/${el.img}.jpg`} alt=""/>
                                        </div>
                                        <p className={styles.description}>{el.text}</p>
                                </Link>)
                        })}
                    </div>
                </div>
                <div className={styles.additionaly}>
                    <div className={styles.youtube}>
                        <div>Приєднуйтеся до нашого YouTube каналу, щоб дізнаватися більше</div>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/vllmQnjTXIw?si=t2nCvWqhtw-hJFTo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    <div className={styles.youtube}>
                        <div>Корпус нашої кафедри знаходиться за адресою:</div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2573.3628036394703!2d24.0118619!3d49.8356402!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add790d2c8979%3A0x816b961499e08d49!2z0J3QoyAi0JvRjNCy0ZbQstGB0YzQutCwINC_0L7Qu9GW0YLQtdGF0L3RltC60LAi!5e0!3m2!1suk!2sus!4v1687286764587!5m2!1suk!2sus" width="560" height="315" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
         </div>
         
         
        </>
        
    );
}

export default Welcome;