import React from 'react';
import styles from './Staff.module.css'
import backendRequest from "../../axios"
import { useQuery } from "@tanstack/react-query"
import StaffBlock from './StaffBlock';
import LoadingSpinner from "../Spinner"

function TeachersStaff() {
    const { isLoading, error, data } = useQuery(["teachers"], () =>
        backendRequest.get("/teachers").then((res) => {
            return res.data.filter(t => t.published === 1);
      }));

    let heads = []
    let deputies = []
    let professors = []
    let docents = []
    let senior_lecturers = []
    let assistants = []
    if (data)
    {
        heads = data.filter(t => t.position_id == 1)
        deputies = data.filter(t => t.position_id == 2)
        professors = data.filter(t => t.position_id == 3)
        docents = data.filter(t => t.position_id == 4)
        senior_lecturers = data.filter(t => t.position_id == 5)
        assistants = data.filter(t => t.position_id == 6)
    }

    return (
        <div>
            <div className={styles.staff_title}>
                Викладачі кафедри "Прикладна математика" ({data ? data.length : 0})
            </div>
            {
                error
                ? "Щось пішло не так!"
                : isLoading
                ? <LoadingSpinner/>
                :
                <div className={styles.staff}>
                    <StaffBlock data = {heads} position = "head"/>
                    <StaffBlock data = {deputies} position = "deputy"/>
                    <StaffBlock data = {professors} position = "professor"/>
                    <StaffBlock data = {docents} position = "docent"/>
                    <StaffBlock data = {senior_lecturers} position = "senior_lecturer"/>
                    <StaffBlock data = {assistants} position = "assistant"/>
                </div>
            }
        </div>
    );
}

export default TeachersStaff