import React from "react";
import {useState,useEffect} from "react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import styles from './Gallery.module.css'
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import backendRequest from "../../axios";
import LoadingSpinner from "../Spinner";
import Page404 from "../errorPages/Page404";

function Gallery(props){
    const { pathname } = useLocation();

    const parsedPath = pathname.split('/');
    const galleryId = parsedPath[parsedPath.length - 1];
    const [activeImage, setActiveImage] = useState(null);
    const [blobs, setBlobs] = useState([]);
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        heigth: window.innerHeight
    })
    const [trigger, setTrigger] = useState(true);

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        window.removeEventListener("resize", handleResize, false);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    useEffect(() => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }, [galleryId])
    
    const { isLoading, error, data, refetch } = useQuery(["getGalleryByIdPublic", galleryId], () => 
        backendRequest.get(`/galleries/${galleryId}`).then((res) => {
            return res.data[0];
        }));

    useEffect(() => {
        let blobsWithIds = [];

        data && data.images.forEach(el => {
            let img = document.getElementById("photo_" + el.id);
            backendRequest.get("/download/" + el.path_name, {responseType: "blob"})
                .then((res) => {
                    var fr = new FileReader();
                    fr.onload = () => {
                        img.src = fr.result;
                    }
                    fr.readAsDataURL(res.data);

                    blobsWithIds.push({id: el.id, blob:res.data, title:el.title})
                });
        })

        data && setBlobs(blobsWithIds);
        data && setActiveImage(data.images[0].id);
    }, [data])

    useEffect(() => {
        if (data && activeImage && blobs.length > 0)
        {
            let image = blobs.filter(el => el.id == activeImage)[0];
            let img = document.getElementById("mainImage");
            var fr = new FileReader();
            fr.onload = () => {
                img.src = fr.result;
            }
            fr.readAsDataURL(image.blob);
            let p = document.getElementById("mainTitle");
            if (p)
            {
                p.innerHTML = image.title;
            }
        }
    }, [activeImage, dimensions, trigger])

    const asd = (id) => {
        setTrigger(false);
        setActiveImage(id);
    }

    //const [numbers, setNumbers] = useState([]);
    
    //useEffect(() => {
    //    let num = [];
    //
    //    if (data)
    //    {
    //        for (let i = 0; i < data.images.length; i++) {
    //            num.push(i);
    //        }
    //    }
    //
    //    setNumbers(num);
    //}, [data]);
    //
    //const [state,setState]=useState(0)
    //
    //useEffect(()=>{
    //    const interval = setInterval(() => {
    //        setState(prevState => (prevState !== names.length - 1 ? prevState + 1 : 0));
    //      }, 8000);
    //    
    //      return () => clearInterval(interval);
    //})
    //
    //const next=()=>{
    //    if(state!==names.length-1){
    //        setState(prev => prev + 1)
    //    }
    //    else{
    //        setState(0)
    //    }
    //}
    //
    //const previous=()=>{
    //    if(state!==0){
    //        setState(prev => prev - 1)
    //    }
    //    else{
    //        setState(names.length-1)
    //    }
    //}
  
    return (
        <>
        {
            error
            ? error.response.status = 404 ? <Page404/> : "Щось пішло не так!"
            : isLoading
            ? <LoadingSpinner/>
            : <>
                <p className={styles.title}>{data.title}</p>
                {
                    blobs.length > 0 ? <>
                        <div className={styles.main}>
                            <img id="mainImage" src="" alt="" />
                        </div>
                        <p className={styles.main_title} id="mainTitle"></p>
                        <div className={styles.navigation}>
                            {
                                data && data.images.map(img=><div key={img.id} className={activeImage==img.id ? styles.active : styles.dots} onClick={()=>asd(img.id)}></div>)
                            }
                        </div>
                    </> 
                    : <p className={styles.main_title}> Оберіть фотографію, щоб розпочати перегляд </p>
                }
                <div className={styles.all_photos}>
                    {
                        data && data.images.map(img => <img key={img.id} id={"photo_" + img.id} src="" alt={img.title} onClick={() => asd(img.id)}/>)
                    }
                </div>
            </>
            
            /*<p className={styles.title}>data.title</p>
            <div className={styles.main}>
                <NavigateBeforeIcon className={styles.arrow} onClick={()=>previous()}/>
                <div className={styles.content}>
                    <img src={`/static/img/${names[state]}.jpg`} alt="" />
                </div>
                <NavigateNextIcon className={styles.arrow} onClick={()=>next()}/>
                <div className={styles.navigation}>
                    {
                        numbers.map(num=><div className={state==num ? styles.active : styles.dots} onClick={()=>setState(num)}></div>)
                    }
                </div>
            </div>
            <div className={styles.all_photos}>
                {
                    numbers.map(num=><img src={`/static/img/${names[num]}.jpg`} alt="" onClick={()=>setState(num)}/>)
                }
            </div>*/
            }
        </>
    )
}

export default Gallery



