import backendRequest from "./axios";

const loadImage = async (id, img_path) => {
    const image = await backendRequest.get(`/download/` + img_path, {responseType:"blob"});
    
    const fr = new FileReader();
    fr.onload = () => {
        document.getElementById(id).src = fr.result;
    }
    fr.readAsDataURL(image.data);
}

export default loadImage;