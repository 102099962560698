import React from 'react';
import styles from './Page404.module.css'
import { Link } from 'react-router-dom';

function Page404() {
    return (
        <div className={styles.container}>
            Ой-ой! Такої сторінки у нас не існує! (404 Not Found)
            <p><Link to="/"> Повернутись на головну </Link></p>
        </div>
    )
}

export default Page404;