import React, { useState } from "react";
import { saveAs } from 'file-saver';
import maps_data from './file (51).json'
import subjects from './file (49).json'
import styles from './Coursesmap.module.css'

function Coursesmap(props){

    const categories=[['Предмет професійної підготовки','Дисципліна спеціалізації "Прикладна математика"','Дисципліна спеціалізації "Інформатика"'],
    ['Предмет професійної підготовки','Дисципліна вибіркового блоку "Актуарна та фінансова математика"','Дисципліна вибіркового блоку "Моделювання фінансових інструментів"']]

    const[checkbox,setCheckbox]=useState([false,false,false,false,false,false,false,false])

    const checkboxChange=(id)=>{
        let check = [...checkbox] // Створюємо новий масив на основі checkbox
        check[id] = !check[id]
        setCheckbox(check)
    }

    const newSubject=(map_id,sem)=>{
        const updateMaps=maps.map(map=>{
            if(map.id==map_id){
                let newCourses=map.courses
                if(checkbox[sem])
                {
                    newCourses[sem].push({id:map.courses[sem].length,firstname:'Дисципліна за вибором',secondname:'Дисципліна за вибором',credits:5})
                }
                
                else
                newCourses[sem].push({id:map.courses[sem].length,name:'Дисципліна за вибором',anot:'Дізнатися анотацію курсу буде можливо після вибору дисципліни',credits:5,color:2})
            }
            return map
        })
        setMaps(updateMaps)
        console.log(maps)
    }

    subjects.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();
      
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
    });
    
    const [maps,setMaps]=useState(maps_data)
    const [name,setName]=useState()
    const [state,setState]=useState([true,0])
    const [spec,setSpec]=useState('pm')

    const newMap=()=>{
        setMaps([{icons:{name:spec,id:maps.length},id:maps.length,name:name,courses:[[],[],[],[],[],[],[],[]]},...maps])
    }

    const modify=(map_id,sem,subject_id,obj,value)=>{
        const updateMaps=maps.map(map=>{
            if(map.id==map_id){
                let newCourses=map.courses
                newCourses[sem]=map.courses[sem].map(subject=>{
                    if(subject.id==subject_id){
                        let updateSubject=subject
                        subject[obj]=value
                        if(obj=='name'){
                            subjects.map(sub=>{
                                if(sub.title==subject.name){
                                    updateSubject.anot=sub.anotation
                                    if(sub.anotation.length>1){
                                        updateSubject.color=2
                                    }
                                    else{
                                        updateSubject.color=1
                                    }
                                }
                            })
                        }
                        return updateSubject
                    }
                    return subject
                })
                return {...map,courses:newCourses}
            }
            return map
        })
        setMaps(updateMaps)
    }

    const modifySplit=(map_id,sem,subject_id,obj,value,anot)=>{
        const updateMaps=maps.map(map=>{
            if(map.id==map_id){
                let newCourses=map.courses
                newCourses[sem]=map.courses[sem].map(subject=>{
                    if(subject.id==subject_id){
                        let updateSubject=subject
                        subject[obj]=value
                        if(obj=='firstname' || obj=='secondname'){
                            subjects.map(sub=>{
                                console.log(sub.title)
                                console.log(subject[obj])
                                if(sub.title==subject[obj]){
                                    updateSubject[anot]=sub.anotation
                                }
                            })
                        }
                        return updateSubject
                    }
                    return subject
                })
                return {...map,courses:newCourses}
            }
            return map
        })
        setMaps(updateMaps)
    }

    const Delete=(map_id,sem,sub_id)=>{
        const filterMaps = maps.map(map => {
            if (map.id === map_id) {
              const newfilterCourses = map.courses.map((courses, index) => {
                if (index === sem) {
                  return courses.filter(obj => obj.id !== sub_id);
                }
                return courses
              });
              return { ...map, courses: newfilterCourses };
            }
            return map;
          });
          const updateMaps = filterMaps.map(map => {
            if (map.id === map_id) {
              const newCourses = map.courses.map((courses, index) => {
                if (index === sem) {
                    return courses.map(obj => {
                        if (obj.id > sub_id) {
                          return { ...obj, id: obj.id - 1 };
                        }
                        return obj;
                      });
                }
                return courses
              });
              return { ...map, courses: newCourses };
            }
            return map;
          });
          setMaps(updateMaps);
    }
    const countSemestr=[0,1,2,3,4,5,6,7]
    const saveMaps=()=>{
        const jsonString = JSON.stringify(maps);
        const blob = new Blob([jsonString], { type: 'application/json' });
        saveAs(blob, 'file.json');
    }
    return(
        <>
            {state[0] && <div className={styles.new}>
                <input type="text" onChange={(e)=>setName(e.target.value)}/><br/>
                <input type="radio" name="specialization" id="pm" checked={true} onChange={()=>setSpec('pm')}/><label htmlFor="pm">Прикладна математика та інформатика</label><br/>
                <input type="radio" name="specialization" id="fi" onChange={()=>setSpec('fi')}/><label htmlFor="fi">Фінансовий інжинирінг</label><br/>
                <button className={styles.add} onClick={()=>newMap()} disabled>Добавити карту курсів (функція тимчасово відключена, так як знаходиться у розробці)</button>
                <div className={styles.table}>{maps.map(course=>{
                    return <div className={styles.row}>{course.name}<button onClick={()=>setState([false,course.id])} disabled>Редагувати (відключено)</button></div>
                })}
                </div>
                {/* <div><button onClick={()=>saveMaps()}>Зберегти карту курсів</button></div> */}
            </div>
            }
            {!state[0] &&
            <div className={styles.map}>
            {console.log(maps)}
                {maps.map(map=>{
                    if(map.id==state[1]){
                        return(<>{
                        map.courses.length>0 && map.courses.map((semestr,index)=>{
                            return <>
                            <div className={styles.semestr}>
                                {index + 1} семестр:
                                {semestr.map(subject=>{
                                    return(
                                        <div className={styles.subject}>
                                            {subject.name ? 
                                            <>
                                                <div>{subject.id + 1}</div>
                                                <div className={styles.punkt}>
                                                    <select className={styles.name} value={subject.name} onChange={(e)=>modify(map.id,index,subject.id,'name',e.target.value)}>
                                                        {subjects.map(sub=>{
                                                            return <option>{sub.title}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                <div className={styles.punkt}>
                                                    <input className={styles.credits} type="number" step='0.5' value={subject.credits} onChange={(e)=>modify(map.id,index,subject.id,'credits',e.target.value)}/>
                                                </div>
                                                {subject.anot.length>2 ? <span>Предмет професійної підготовки</span> : <span>Предмет загальної підготовки</span>}
                                                <button onClick={()=>Delete(map.id,index,subject.id)}>Delete</button>
                                            </>
                                            :
                                            <>
                                                <div>{subject.id + 1}</div>
                                                <div className={styles.split}>
                                                    <select className={styles.name} value={subject.firstname} onChange={(e)=>modifySplit(map.id,index,subject.id,'firstname',e.target.value,'firstanot')}>
                                                        {subjects.map(sub=>{
                                                            if(sub.anotation.length>1)
                                                            return <option>{sub.title}</option>
                                                        })}
                                                    </select>
                                                    <select className={styles.name} value={subject.secondname} onChange={(e)=>modifySplit(map.id,index,subject.id,'secondname',e.target.value,'secondanot')}>
                                                        {subjects.map(sub=>{
                                                            if(sub.anotation.length>1)
                                                            return <option>{sub.title}</option>
                                                        })}
                                                    </select>
                                                </div>
                                               <input className={styles.credits} type="number" step='0.5'  value={subject.credits} onChange={(e)=>modify(map.id,index,subject.id,'credits',e.target.value)}/>
                                                <div className={styles.split}>
                                                    {map.icons.name=='pm' && <>
                                                    <span>Спеціальність Прикладна математика</span>
                                                    <span>Спеціальність Інформатика</span></>}
                                                    {map.icons.name=='fi' && <>
                                                    <span>Спеціальність Актуарна та фінансова математика</span>
                                                    <span>Спеціальність Моделювання фінансових інструментів</span></>}
                                                </div>
                                                <button onClick={()=>Delete(map.id,index,subject.id)}>Delete</button>
                                            </>
                                            }
                                            </div>
                                        )
                                })}
                                <div>
                                    Розділений предмет<input type="checkbox" value='текст' onChange={()=>checkboxChange(index)}/>
                                    <button onClick={()=>newSubject(map.id,index)}>Додати новий предмет</button>
                                </div>    
                            
                            </div>
                            </>
                        })
                    }
                    <div><button onClick={()=>setState([true,0])}>Зберегти карту курсів</button></div>
                    </>)
                    }
                })}
            </div>
            }
        </>
    )
}

export default Coursesmap