import React,{useState} from "react";
import styles from './Department.module.css'


function Department(props){

    const [state,setState]=useState(true)

    const info=[
        {text:[{info:"Кафедра прикладної математики Національного університету «Львівська політехніка» вже більше 50 років зберігає і примножує багаті наукові й освітні традиції, невтомно готує нові покоління кваліфікованих кадрів у галузях прикладної математики та інформатики, збагачуючи їх інтелектуально і духовно.",style:1}]},
        {title:"Освітні програми (спеціалізації)",list:[{info:"Прикладна математика та інформатика (бакалавр) ",style:1},{info:"Фінансовий інжиніринг (бакалавр)",style:1},{info:"Прикладна математика (магістр)",style:1},{info:"Інформаційно-комунікаційні технології (магістр)",style:1}]},
        {title:"Навчальний процес",text:[{info:"Студенти кафедри отримують глибоку фундаментальну та спеціальну підготовку під час лекційних, практичних і лабораторних занять. Навички практичної роботи одержують під час практики у провідних ІТ-компаніях, науково-дослідних установах, обчислювальних центрах підприємств. Кращі студенти кафедри проходять стажування, навчальні практики у престижних вищих навчальних закладах країн Європи.",style:1}]},
        {title:"Наукова робота",text:[{info:"На кафедрі ведеться",style:1},{info:" науково – дослідна робота",style:2},{info:" на основі грантів України та Європи. Є аспірантура та докторантура. Кращі студенти кафедри залучаються до науково-дослідної роботи та рекомендуються для вступу до аспірантури. Ними керують провідні викладачі кафедри. Багато випускників нашої кафедри стали кандидатами і докторами наук. Освітньо-професійні навчальні програми забезпечують достатній для сучасного ринку праці рівень підготовки. Усе це дає можливість нашим випускникам стати кваліфікованими спеціалістами з прикладної математики та інформатики і знайти себе на ринках праці України та світу.",style:1}]},
        {title:"Колектив кафедри",img:"/static/img/teachers.jpg"},
    ]
    return(
        <>
            <div className={styles.title}>Про кафедру</div>
            {info.map((el,index)=>{
                if(el.img){
                        return(
                            <div key={el.img}>
                                {el.title && <div className={styles.img_title}>{el.title}</div>}
                                <div onClick={()=>setState(!state)} className={state ? styles.img : styles.active}><img src={el.img}></img></div>
                            </div>
                        )
                }
                return(
                    <div key={index} className={styles.paragraph}>
                        {el.title && <div className={styles.sub_title}>{el.title}</div>}
                        {el.text && el.text.map(
                            (element,id)=><span key={id} className={styles.text} style={element.style==2 ? {fontWeight:700} : {}}>{element.info}</span>
                        )}
                        {el.list && <ul>
                            {el.list.map(elem=><li key={elem.id}>{elem.info}</li>)}
                            </ul>}
                    </div>
                )
            })}
        </>
    )
}

export default Department