import React,{useState} from "react";
import styles from './Excursion.module.css'

function Excursion(props){

    const [state,setState]=useState([false,false])

    return(
        <>
            <div className={styles.title}>Шановні абітурієнти, шановні батьки!</div>
            <div className={styles.main}>
                <div>Обираєте, куди поступити після 11 класу, і маєте зацікавлення до сфери ІТ, програмування, комп’ютерних та точних наук?</div>
                <div>Кафедра Прикладної математики НУ «Львівська політехніка» запрошує на екскурсії до нашого закладу. Ви дізнаєтеся про навчальні програми, дуальну освіту спільно з компанією SoftServe – однією з провідних у галузі ІТ, програму подвійних дипломів із ЗВО Європи тощо. </div>
                <div>Запис за телефоном: (096)-46-17-17-5 – Ліснічук Андрій Євгенович. Будемо вдячні за дзвінок у робочі години з 8 до 17 год з понеділка по п’ятницю.</div>
                <div>Адреса кафедри Прикладної математики: м. Львів, вул. Митрополита Андрея, 5, IV навчальний корпус, кім. 213.</div>
                <img className={state[0] ? styles.active : styles.img} onClick={()=>setState([!state[0],false])} alt='Фото з екскурсії' src="static\img\ekskursiya1.jpg"/>
                <div>Наші контакти та соц.мережі:</div>
                <div>Телефон: (032)258-23-68, (096)461-71-75</div>
                <div>Пошта: pm.dept@lpnu.ua</div>
                <div><a href="https://t.me/amath_lp_edu_ua">Telegram-канал</a></div>
                <div><a href="https://www.facebook.com/applymathlp">Facebook</a></div>
                <div><a href="https://www.youtube.com/@user-wv3wj5nv7e">YouTube</a></div>
                <div><a href="https://www.instagram.com/apply_math_lp?igsh=NDhzdHUzNDY2ZmR2">Instagram</a></div>
            </div>
        </>
    )
}

export default Excursion