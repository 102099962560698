import React, { useEffect, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styles from "../AdminMain.module.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useQuery } from "@tanstack/react-query";
import backendRequest from "../../../../axios";
import LoadingSpinner from "../../../Spinner";
import autosize from "../../../../autosize";

function AdminTeacher(){
    const location = useLocation();
    const navigate = useNavigate();
    const {state, pathname} = location;

    const [mainInfo, setMainInfo] = useState({
        pib1: "",
        pib2: "",
        pib3: "",
        email: "",
    })
    const [smallLogo, setSmallLogo] = useState(null);
    const [bigLogo, setBigLogo] = useState(null);
    const [fullInfo, setFullInfo] = useState({});
    const [errorMessage, setErrorMessage] = useState("Не вдалось виконати операцію. Проблеми із сервером...");
    const [baddataDisplay, setBaddataDisplay] = useState("none");
    const [successDisplay, setSuccessDisplay] = useState("none");
    
    const isNew = 
        state != null
        ? 'isNew' in state
        : pathname == "/admin/teachers/new";

    const { isLoading:keysLoading, error:keysError, data:keys} = useQuery(["descKeys"], () =>
        backendRequest.get("/teachers/descKeys/all").then(async (res) => {
            return res.data;
    }));

    useEffect(() => {
        const fetch = async () => {
            const pathDelails = pathname.split('/');
            const teacherId = pathDelails[pathDelails.length - 1];

            await backendRequest.get("/teachers/" + teacherId + "/info").then(async (response) => {
                
                const bigImg = await backendRequest.get(`/download/` + response.data[0].big_img_path, {responseType:"blob"});
                changeLogo("big-logo", bigImg.data, false);
                const smallImg = await backendRequest.get(`/download/` + response.data[0].small_img_path, {responseType:"blob"});
                changeLogo("small-logo", smallImg.data, true);

                const pib = response.data[0].full_name.split(' ');
                setValue("pib1", pib[0]);
                setValue("pib2", pib[1]);
                setValue("pib3", pib[2]);
                setValue("email", response.data[0].email);

                loadValuestoGrid(response.data[0].description);
                setFullInfo(response.data[0]);
                return response.data;
            });
        }

        fetch();    
    }, []);

    const positions = [{value: 1, title:"Завідувач кафедрою"},{value: 2, title:"Заступник завідувача кафедрою"},{value: 3, title:"Професор"}, {value: 4, title:"Доцент"}, {value: 5, title:"Старший викладач"}, {value: 6, title:"Асистент"}];

    const loadValuestoGrid = (data) => {
        data && data.map(key => {
            if (key.desc_type === "text")
            {
                setValue("teacherInfo_" + key.id, key.desc_value);
            }
            if (key.desc_type === "list")
            {
                const parsedValue = JSON.parse(key.desc_value);
                parsedValue.forEach(val => {
                    handleAddElementToList(null, key.id);
                })

                const div = document.getElementById("teacherInfo_" + key.id);
                var divChildren = [...div.children];
                divChildren.forEach((child, count) => {
                    if (!parsedValue[count])
                    {
                        div.removeChild(child);
                    }
                    else {
                        setValue(child.id, parsedValue[count]);
                    }
                });
            }
            if (key.desc_type === "doub-list")
            {
                const parsedValue = JSON.parse(key.desc_value);
                let count = 0;
                parsedValue.forEach(val => {
                    handleAddDoubList(null, key.id);
                })

                const div = document.getElementById("teacherInfo_" + key.id);
                var divChildren = [...div.children];
                divChildren.filter(child => child.nodeName == "TEXTAREA").forEach(listKey => {
                    if (!parsedValue[count])
                    {
                        div.removeChild(listKey);
                        const valuesList = document.getElementById(listKey.id + "_list");
                        div.removeChild(valuesList);
                    }
                    else {
                        setValue(listKey.id, parsedValue[count].left);

                        parsedValue[count].right.forEach((val) => {
                            handleAddDoubListRight(null, listKey.id + "_list");
                        })

                        var listValuesNodes = document.getElementById(listKey.id + "_list");
                        var listValues = [...listValuesNodes.children];

                        listValues.filter(child => child.nodeName == "TEXTAREA").forEach((listValue, listCount) => {
                            if (!parsedValue[count].right[listCount])
                            {
                                listValuesNodes.removeChild(listValue);
                            }
                            else {
                                setValue(listValue.id, parsedValue[count].right[listCount]);
                            }
                        });

                        count++;
                    }
                })
            }
        });
    }

    const handleMainInfoChange = (e) => {
        setMainInfo(prev => ({...prev, [e.target.id]: e.target.value}));
    }

    const handleSmallLogoChange = (e) => {
        var file = e.target.files[0];
        changeLogo("small-logo", file, true);
    }

    const handleBigLogoChange = (e) => {
        var file = e.target.files[0];
        changeLogo("big-logo", file, false);
    }

    const changeLogo = (id, image, isSmall) => {
        var div = document.getElementById(id);
        var img = document.createElement("img");
        var fr = new FileReader();
        fr.onload = () => {
            img.src = fr.result;
        }
        fr.readAsDataURL(image);
        div.replaceChildren(img);
        isSmall ? setSmallLogo(image) : setBigLogo(image);
    }

    const loadAutosize = (event, rowCount) =>
    {
        autosize(event.target.id, rowCount);
    }

    const setValue = (id, value) => {
        const obj = document.getElementById(id);
        if (obj == null) console.log(id);
        obj.value = value;
        setMainInfo(prev => ({...prev, [id]: value}));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!smallLogo || !bigLogo 
            || mainInfo.pib1.length < 1
            || mainInfo.pib2.length < 1
            || mainInfo.pib3.length < 1
            || mainInfo.email.length < 1) 
        {
            setBaddataDisplay("block");
            setErrorMessage("Обов'язкові поля для заповнення: ПІБ, пошта, фотографія та фотографія-картка!");
            return;
        }

        const description = [];
        // Forming description array 
        keys.map(key => {
            if (key.desc_type === "text")
            {
                let value = document.getElementById("teacherInfo_" + key.id).value;
                if (value && value.trim().length > 0) description.push({desc_id:key.id, desc_value:value});
            }
            if (key.desc_type === "list")
            {
                let value = [];
                const div = document.getElementById("teacherInfo_" + key.id);
                var divChildren = [...div.children];
                divChildren.forEach(child => {
                    const val = child.value;
                    if(val && val.trim().length > 0) value.push(val);
                });
                if (value.length > 0) description.push({desc_id:key.id, desc_value:JSON.stringify(value)});
            }
            if (key.desc_type === "doub-list")
            {
                let value = [];
                const div = document.getElementById("teacherInfo_" + key.id);
                var divChildren = [...div.children];
                divChildren.forEach(listKey => {
                    if (listKey.nodeName == "TEXTAREA")
                    {
                        let val = listKey.value;
                        if(val && val.trim().length > 0)
                        {
                            let valueArray = [];
                            var listValuesNodes = document.getElementById(listKey.id + "_list");
                            var listValues = [...listValuesNodes.children];
                            listValues.forEach(listValue => {
                                const v = listValue.value;
                                if (v && v.trim().length > 0) valueArray.push(v);
                            })
        
                            if (valueArray && valueArray.length > 0) value.push({left:val, right:valueArray});
                        }
                    }
                })

                if (value.length > 0) description.push({desc_id:key.id, desc_value:JSON.stringify(value)});
            }
        });

        const full_name = mainInfo.pib1 + " " + mainInfo.pib2 + " " + mainInfo.pib3;
        const position_id = document.getElementById("position").value;

        let formData = new FormData();
        formData.append("file", smallLogo);
        const smallLogoName = await backendRequest.post("/upload", formData);
        formData.set("file", bigLogo);
        const bigLogoName = await backendRequest.post("/upload", formData);

        if(isNew)
        {
            await backendRequest.post("/teachers", {full_name, position_id, email: mainInfo.email});
            const {data:arr} = await backendRequest.get("/teachers");
            const { id, content_id } = arr[arr.length - 1];
            await backendRequest.post("/teachers/" + id + "/descValues", description);
            await backendRequest.post("/attachments", {parent_content_id:content_id, title: full_name, path_name:smallLogoName.data, type:"small-avatar"});
            await backendRequest.post("/attachments", {parent_content_id:content_id, title: full_name, path_name:bigLogoName.data, type:"big-avatar"});
        }
        else {
            await backendRequest.put("/teachers/" + fullInfo.id, {full_name, position_id, email: mainInfo.email});
            await backendRequest.put("/teachers/" + fullInfo.id + "/descValues", description);
            await backendRequest.put("/attachments/" + fullInfo.small_img_id, {title: full_name, path_name:smallLogoName.data});
            await backendRequest.put("/attachments/"+ fullInfo.big_img_id, {title: full_name, path_name:bigLogoName.data});
        }

        setSuccessDisplay("block");
        setBaddataDisplay("none");
        setTimeout(() => navigate('/admin/teachers'), 1000);
    }

    const handleAddElementToList = (event, id) => {
        event && event.preventDefault();
        var div = document.getElementById("teacherInfo_" + id);
        var newTextArea = document.createElement('textarea');
        newTextArea.id = "teacherInfo_" + id + "_" + (div.childElementCount + 1);
        newTextArea.placeholder = "Рядок списку";
        newTextArea.onkeyup = (event) => loadAutosize(event, 7);
        div.appendChild(newTextArea);
    }

    const handleAddDoubList = (event, id) => {
        event && event.preventDefault();
        var div = document.getElementById("teacherInfo_" + id);
        var divChildren = [...div.children];
        var divDoubListsAmount = divChildren.filter(el => el.type == "textarea").length;

        var newTextArea = document.createElement('textarea');
        newTextArea.id = "teacherInfo_" + id + "_" + (divDoubListsAmount + 1);
        newTextArea.className = styles.doublist_key;
        newTextArea.maxLength = 50;
        newTextArea.placeholder = "Ключ списку";
        newTextArea.onkeyup = (event) => loadAutosize(event, 3);
        div.appendChild(newTextArea);

        var listDiv = document.createElement('div');
        listDiv.id = "teacherInfo_" + id + "_" + (divDoubListsAmount + 1) + "_list";
        div.appendChild(listDiv);

        var button = document.createElement('button');
        button.innerHTML = "Додати елемент(рядок) у список";
        button.onclick = (event) => handleAddDoubListRight(event);
        listDiv.appendChild(button);

        var listTextArea = document.createElement('textarea');
        listTextArea.id = "teacherInfo_" + id + "_" + (divDoubListsAmount + 1) + "_list_1";
        listTextArea.placeholder = "Рядок списку";
        listTextArea.onkeyup = (event) => loadAutosize(event, 7);
        listDiv.appendChild(listTextArea);
    }

    const handleAddDoubListRight = (event, elementId) => {
        event && event.preventDefault();
        var id = elementId || event.target.parentElement.id;
        var div = document.getElementById(id);
        var divChildren = [...div.children];
        var divListsAmount = divChildren.filter(el => el.type == "textarea").length;

        var newTextArea = document.createElement('textarea');
        newTextArea.id = id + "_" + (divListsAmount + 1);
        newTextArea.placeholder = "Рядок списку";
        newTextArea.onkeyup = (event) => loadAutosize(event, 7);
        div.appendChild(newTextArea);
    }

    return(
        <div className={styles.container}>
            <div className={styles.dashboard}>
                <div className={styles.dashboard_title}>{isNew ? "Додати нового викладача" : "Редагувати викладача"}</div>
                <Link className={styles.return} to="/admin/teachers"><ArrowBackIcon/>Повернутись до списку викладачів</Link>
            </div>
            {
                keysError 
                ? keysError.response.data
                : keysLoading 
                ? <LoadingSpinner/>
                :
                <div className={styles.content + " " + styles.add_teacher}>
                    <form className={styles.add_new_teacher_form}>
                        <div className={styles.logos}>
                            <div className={styles.small_logo_block}>
                                Малий аватар (картка, 267х200)
                                <input style={{ display: "none" }} type="file" id="small-logo-input" name="" accept="image/png, image/jpeg" onChange={(e) => handleSmallLogoChange(e)} />
                                <div className={styles.small_logo} id="small-logo"></div>
                                <label htmlFor="small-logo-input"> Обрати фото </label>
                            </div>
                            <div className={styles.big_logo_block}>
                                Великий аватар
                                <input style={{ display: "none" }} type="file" id="big-logo-input" name="" accept="image/png, image/jpeg" onChange={(e) => handleBigLogoChange(e)}  />
                                <div className={styles.big_logo} id="big-logo"></div>
                                <label htmlFor="big-logo-input"> Обрати фото </label>
                            </div>
                        </div>
                        <div className={styles.header}>
                            <div className={styles.teacher_main_info}>
                                <div><label>Прізвище:</label><textarea id="pib1" maxLength="30" required onChange={handleMainInfoChange}></textarea></div>
                                <div><label>Ім'я:</label><textarea id="pib2" maxLength="30" required onChange={handleMainInfoChange}></textarea></div>
                                <div><label>По-батькові:</label><textarea id="pib3" maxLength="30" required onChange={handleMainInfoChange}></textarea></div>
                                <div><label>Посада:</label><select id="position">{positions.map(item =><option key={item.value} value={item.value}>{item.title}</option>)}</select></div>
                                <div><label>e-mail:</label><textarea id="email" maxLength="50" required onChange={handleMainInfoChange}></textarea></div>
                            </div>
                        </div>
                        <div className={styles.body}>
                            {
                                keys.map(key => 
                                    <div key={key.id} className={styles.paragraph}>
                                    {
                                        key.desc_type === "text"
                                        ?
                                            <>
                                                {key.desc_title}
                                                <textarea id={"teacherInfo_" + key.id} name={key.desc_title} placeholder="Текстове поле" onKeyUp={(event => loadAutosize(event, 7))}></textarea>
                                            </>
                                        : key.desc_type === "list"
                                        ?
                                            <>
                                                {key.desc_title}
                                                <button onClick={(event) => handleAddElementToList(event, key.id)}>Додати елемент(рядок) у список</button>
                                                <div id={"teacherInfo_" + key.id}><textarea id={"teacherInfo_" + key.id + "_1"} name={key.desc_title} placeholder="Рядок списку" onKeyUp={(event => loadAutosize(event, 7))}></textarea>
                                                </div>
                                            </>
                                        :
                                            <>
                                                {key.desc_title}
                                                <button onClick={(event) => handleAddDoubList(event, key.id)}>Додати список</button>
                                                <div className={styles.doublist_grid} id={"teacherInfo_" + key.id}>
                                                    <textarea className={styles.doublist_key} id={"teacherInfo_" + key.id + "_1"} name={key.desc_title} placeholder="Ключ списку" maxLength="50" onKeyUp={(event => loadAutosize(event, 7))}></textarea>
                                                    <div id={"teacherInfo_" + key.id + "_1_list"}>
                                                        <button onClick={(event) => handleAddDoubListRight(event, null)}>Додати елемент(рядок) у список</button>
                                                        <textarea id={"teacherInfo_" + key.id + "_1_list_1"} name={key.desc_title} placeholder="Рядок списку" onKeyUp={(event => loadAutosize(event, 7))}></textarea>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <p className={styles.form_success} style={{display: successDisplay}}>Успіх!</p>
                        <p className={styles.form_warning} style={{display: baddataDisplay}}>{errorMessage}</p>
                        <button className={styles.teacherForm_submit} onClick={handleSubmit}>{isNew ? "Додати викладача" : "Оновити інформацію"}</button>
                    </form>
                </div>
            }
        </div>
    )
}

export default AdminTeacher