import React, { useContext, useEffect, useState } from "react";
import styles from "../AdminMain.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import backendRequest from "../../../../axios";
import { AuthContext } from "../../../../context/userContext";

function AdminUser(){
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const {state, pathname} = location;

    const isNew = 
        state != null
        ? 'isNew' in state
        : pathname == "/admin/users/new";

    const [errorMessage, setErrorMessage] = useState("Не вдалось зареєструвати користувача. Проблеми із сервером...");
    const [baddataDisplay, setBaddataDisplay] = useState("none");
    const [successDisplay, setSuccessDisplay] = useState("none");
    const [formInputs, setFormInputs] = useState({
        login: "",
        display_name:"",
        email:"",
        pwd:""
    })

    const inputsHandle = (e) => {
        setFormInputs(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const addUserHandle = async (e) => {
        e.preventDefault();
        //if(formInputs.login == "")
        //    formInputs.login = currentUser.login;
        //if(formInputs.email == "")
        //    formInputs.email = currentUser.email;
        if (isNew)
        {
            await backendRequest
                .post("/users", formInputs)
                .then(res => {
                    let form = document.getElementById("add_user_form");
                    let elements = form.elements;
                    for (let i = 0, len = elements.length; i < len; ++i)
                        elements[i].readOnly = true;
                    let button = document.getElementById("submit_button");
                    button.disabled = true;
                    setSuccessDisplay("block");
                    setBaddataDisplay("none");
                    setTimeout( () => window.location.replace(process.env.PUBLIC_URL + "/admin/users"), 500);
                })
                .catch(err => {
                    setBaddataDisplay("block");
                    setErrorMessage(err.response.data);
                })
        }
        else {
            await backendRequest
                .put("/users/" + currentUser.id, formInputs)
                .then(res => {
                    let form = document.getElementById("add_user_form");
                    let elements = form.elements;
                    for (let i = 0, len = elements.length; i < len; ++i)
                        elements[i].readOnly = true;
                    let button = document.getElementById("submit_button");
                    button.disabled = true;
                    const user = JSON.parse(localStorage.getItem("user"));
                    user.display_name = formInputs.display_name;
                    user.firstLogin = false;
                    localStorage.setItem("user", JSON.stringify(user));
                    setSuccessDisplay("block");
                    setBaddataDisplay("none");
                    setTimeout( () => navigate('/admin'), 500);
                })
                .catch(err => {
                    console.log(err);
                    setBaddataDisplay("block");
                    setErrorMessage('response' in err ? err.response.data : "Не вдалось оновити користувача. Проблеми із сервером...");
                })
        }
    }

    return(
        <div className={styles.container}>
            <div className={styles.users_title}>
                {isNew ? "Створення користувача" : "Редагування користувача"}
            </div>
            <div className={styles.content + ' ' + styles.user_content}>
                <form id="add_user_form" className={styles.add_user_form}>
                    <input type="text" placeholder={"Логін"} name='login' onChange={inputsHandle}/>
                    <input type="text" placeholder={"Електронна пошта"} name='email' onChange={inputsHandle}/>
                    <input type="password" placeholder="Пароль" name='pwd' onChange={inputsHandle}/>
                    <p className={styles.success} style={{display: successDisplay}}>{isNew ? "Успішно додано!" : "Успішно змінено!"}</p>
                    <p className={styles.warning} style={{display: baddataDisplay}}>{errorMessage}</p>
                    <button id="submit_button" className={styles.submit_button} onClick={addUserHandle}>{isNew ? "Додати користувача" : "Оновити інформацію"}</button>
                </form>
            </div>
        </div>
    )
}

export default AdminUser;