import React, { useState } from "react";
import styles from "./Feedback.module.css";
import ClearIcon from '@mui/icons-material/Clear';

function Feedback() {
    const[popupDisplay, setPopupDisplay] = useState("none");
    const[iconDisplay, setIconDisplay] = useState("inline-block");

    const showPopup = () => {
        setPopupDisplay("block");
        setIconDisplay("none");
    }

    const hidePopup = () => {
        setPopupDisplay("none");
        setIconDisplay("inline-block");
    }

    return (
        <>
            <div className={styles.pop_up} style={{display: popupDisplay}}>
                <div className={styles.container}>
                    <button className={styles.exit_button} onClick={hidePopup}>
                        <ClearIcon/>
                    </button>
                    <p className={styles.header}>Надішліть запитання на кафедру і ми з радістю дамо на нього відповідь!</p>
                    <div className={styles.form}>
                        <select className={styles.dropdown}>
                            <option selected disabled value="default">Хто цікавиться?</option>
                            <option value="student">Студент\Абітурієнт</option>
                            <option value="parents">Родич абітурієнта\студента</option>
                            <option value="business">Бізнес-зацікавлений</option>
                            <option value="scientist">Науковець</option>
                        </select>
                        <textarea className={styles.sender_email} name="senderEmail" placeholder="Адреса вашої електронної скриньки (для відповіді)"/>
                        <textarea className={styles.sender_email} name="name" placeholder="Як до Вас звертатись?"/>
                        <textarea className={styles.question} name="question" placeholder="Що Вас цікавить?"/>
                    </div>
                    <button className={styles.submit_button}> Надіслати </button>
                </div>
            </div>
            <button className={styles.main} style={{display: iconDisplay}} title="Маєте запитання? Надішліть його на кафедру!" onClick={showPopup}/>
        </>
    )
}

export default Feedback;