import React, { useEffect } from 'react';
import styles from './MobileHeader.module.css'
import {Link} from "react-router-dom";
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from 'react'
import MenuElement from "./MenuElement";
import backendRequest from '../../axios';


function MobileHeader(props) {
    const [state,setState]=useState(false)
    const [galleries, setGalleries] = useState([{text:"Галереї тимчасово відсутні!", link:'/'}]);

    const click=(newData)=>{
        setState(newData)
    }

    const info=[
        {link:'/',text:'Кафедра',arr:[{link:'department',text:'Про кафедру'},{link:'history',text:'Історія кафедри'},{link:'heads',text:'Завідувачі кафедрою'},{link:'graduates',text:'Відомі випускники'},{link:'magazine',text:'Науковий журнал'},{link:'labs',text:'Навчальні лабораторії'}]},
        {link:'staff/teachers',text:'Викладачі',arr:[]},
        {link:'/',text:'Галерея',arr:[{link:'fifty',text:'Кафедрі 50 років'},{link:'',text:'Кафедрі 40 років'},{link:'',text:'Вручення відзнак'}]},
        {link:'/',text:'Студентам',arr:[{link:'https://vns.lpnu.ua/my/', text:'Віртуальне навчальне середовище'}, {link:'https://student.lpnu.ua/students_schedule?departmentparent_abbrname_selective=%D0%86%D0%9C%D0%A4%D0%9D&semestrduration=1', text:'Розклад занять'}]},
        {link:'/',text:'Абітурієнтам',arr:[{link:'invitation',text:'Запрошуємо на навчання'},{link:'programs', text:'Освітні програми'},{link:'courses', text:'Карти курсів'},{link:'dual-education', text:'Дуальна освіта'},{link:'dual',text:'Подвійне дипломування'}, {link:'companies', text:'Місця роботи'}, {link:'excursions', text:'Екскурсії кафедрою!'}]},
    ]

    useEffect(() => {
        async function fetchGalleries() {
            let arr = [];
            const res = await backendRequest.get("/galleries");

            res.data && res.data.forEach(element => {
                arr.push({link:"galleries/" + element.id, text:element.title});
            });
            setGalleries(arr);
        }

        fetchGalleries();
    }, []);

    return (
        <>
            <div className={styles.info}>

                <div className={styles.div}> <BusinessIcon className={styles.img}/> 79013, Львів 13, вул. Митрополита Андрея 5, IV навчальний корпус, кімната 213</div>
                <div> <PhoneIcon className={styles.img}/> +38 (032) 258-23-68</div>
                <div> <MailIcon className={styles.img}/>pm.dept@lpnu.ua</div>
            </div>
            <hr/>
            <div className={styles.nav}>
                 <div className={state ? styles.menu : styles.menu0}>
                        {info.map(el=>
                            <MenuElement key={el.text} props={el.text == 'Галерея' ? {link:'/',text:'Галерея',arr:galleries} : el} onMenuClick={click}/>
                        )}
                 </div>
                <button  className={state ? styles.menu_button_open : styles.menu_button} onClick={()=>click(!state)}>
                    <span className={styles.button_span}></span>
                    <span className={styles.button_span}></span>
                    <span className={styles.button_span}></span>
                </button>
                <Link style={{display:'contents'}} to=''><img src="/static/img/am_newlogo.png" alt="logo" className={styles.logo}/></Link>
            </div>
        </>

    );}

export default MobileHeader;