import React, { useEffect, useState } from 'react';
import styles from "../AdminMain.module.css";
import backendRequest from '../../../../axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import autosize from '../../../../autosize';

function AdminNewGallery() {
    const navigate = useNavigate();

    const [photos, setPhotos] = useState([]);
    const [errorMessage, setErrorMessage] = useState("Не вдалось виконати операцію. Проблеми із сервером...");
    const [baddataDisplay, setBaddataDisplay] = useState("none");
    const [successDisplay, setSuccessDisplay] = useState("none");

    const handleAddPhotos = (e) => {
        var files = e.target.files;

        setPhotos(prev => [...prev, ...files]);
    }

    useEffect(() => {
        photos.map((photo, id) => getImage(photo, id));
    }, [photos])

    const getImage = (photo, id) => {
        const img = document.getElementById("photo_" + id);
        var fr = new FileReader();
        fr.onload = () => {
            img.src = fr.result;
        }
        fr.readAsDataURL(photo);
        autosize("photo_" + id + "_title", 5);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const title = document.getElementById("title").value;
        if (!title || !title.trim())
        {
            setBaddataDisplay("block");
            setErrorMessage("Будь ласка, виберіть назву для галереї!");
            return;
        }

        if (photos.length === 0)
        {
            setBaddataDisplay("block");
            setErrorMessage("Галерея повинна містити мінімум одну фотографію!");
            return;
        }

        await backendRequest.post("/galleries", {title : title});
        const {data:arr} = await backendRequest.get("/galleries");
        const { content_id } = arr[arr.length - 1];

        photos.forEach(async (photo, id) => {
            const photoTitle = document.getElementById("photo_" + id + "_title").value;

            let formData = new FormData();
            formData.append("file", photo);
            backendRequest.post("/upload", formData)
                .then(async (res) => {
                    console.log(res.data);
                    console.log(content_id);
                    await backendRequest.post("/attachments", {parent_content_id:content_id, title: photoTitle, path_name:res.data, type:"gallery-photo"});
                })
        });

        setSuccessDisplay("block");
        setBaddataDisplay("none");
        setTimeout(() => navigate('/admin/galleries'), 1000);
    }
    
    return (
        <div className={styles.container}>
            <Link className={styles.return} to="/admin/galleries"><ArrowBackIcon/>Повернутись до списку галерей</Link>
            <textarea id="title" placeholder="Назва галереї" className={styles.gallery_title}/>
            <div className={styles.flex_container}>
                {
                    photos.map((photo, id) => 
                    <div className={styles.gallery_card} id={id}>
                        <img id={"photo_" + id} src=""/>
                        <textarea className={styles.gallery_new_photo_title} id={"photo_" + id + "_title"} placeholder="Опис фото(необов'язково)" />
                    </div>
                )}
                <div className={styles.gallery_card + ' ' + styles.add_new_card}>
                    <label className={styles.flex_container} htmlFor="photo-input">
                        <img src="/static/img/add_new.png"/>
                        <input style={{ display: "none" }} type="file" id="photo-input" name="" accept="image/png, image/jpeg" multiple="multiple" onChange={(e) => handleAddPhotos(e)} />
                        Додати фотографії
                    </label>
                </div>
            </div>
            <div className={styles.flexbox_rows}>
                <p className={styles.form_success} style={{display: successDisplay}}>Успіх!</p>
                <p className={styles.form_warning} style={{display: baddataDisplay}}>{errorMessage}</p>
                <button className={styles.gallery_submit} onClick={handleSubmit}>Створити галерею</button>
            </div> 
        </div>
        
    );
}

export default AdminNewGallery;