import React, { useContext } from "react";
import styles from "./AdminHeader.module.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/userContext";
import LogoutIcon from '@mui/icons-material/Logout';

function AdminHeader(props){
    const {currentUser, logout} = useContext(AuthContext);
    const navigate = useNavigate();

    let profileName = "";

    if (currentUser)
    {
        profileName = currentUser.display_name;
    }
    else
    {
        navigate("/admin/login");
    }

    const handleLogout = async (e) => {
        e.preventDefault();
        await logout();
        navigate("/admin/login");
    }

    return(
        <div className={styles.header}>
            <Link to='/admin'><img className={styles.logo} alt="logo" src={process.env.PUBLIC_URL + "/static/img/am_newlogo.png"}/></Link>
            <div className={styles.title}>
                Адмін панель
            </div>
            <div className={styles.controls}>
                {profileName}
                <button className={styles.logout} onClick={handleLogout}><LogoutIcon/> Вийти </button>
            </div>
        </div>
    )
}

export default AdminHeader;