import React from 'react';
import styles from './Staff.module.css'
import TeacherCard from '../teachers/TeacherCard';

function StaffBlock({ data, position }) {

    const title = 
        position == "head"
        ? "Завідувач кафедрою"
        : position == "deputy"
        ? "Заступники завідувача кафедрою"
        : position == "professor"
        ? "Професори"
        : position == "docent"
        ? "Доценти"
        : position == "senior_lecturer"
        ? "Старші викладачі"
        : position == "assistant"
        ? "Асистенти"
        : "Викладачі"

    const sortByFullName = (a, b) => {
        const nameA = a.full_name.toUpperCase();
        const nameB = b.full_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
    
        return 0;
    }

    return (
        <div className={styles.block}>
            {data.length > 0 && <p className={styles.block_title}>{title} { data.length > 1 ? "(" + data.length + ")" : "" }</p>}
            <div className={styles.cards}>
                {data.sort(sortByFullName).map((x, id) => <TeacherCard info={x} key={id}/> )}
            </div>
        </div>
    );
}

export default StaffBlock