import React, { useContext, useState } from 'react';
import styles from './Login.module.css'
import backendRequest from '../../axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/userContext';

function Login() {
    const navigate = useNavigate();
    const { currentUser, login } = useContext(AuthContext);

    const [errorMessage, setErrorMessage] = useState("Не вдалось увійти у систему. Проблеми із сервером...");
    const [baddataDisplay, setBaddataDisplay] = useState("none");
    const [loginInputs, setLoginInputs] = useState({
        login:"",
        pwd:""
    })

    const inputsHandle = (e) => {
        setLoginInputs(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const loginHandle = async (e) => {
        e.preventDefault();
        try {
            await login(loginInputs);
            navigate("/admin");
        }
        catch (err)
        {
            console.log(err);
            setBaddataDisplay("block");
            setErrorMessage('response' in err ? err.response.data : "Не вдалось увійти у систему. Проблеми із сервером...");
        }
        //const axiosRequest = await backendRequest
        //    .post("/users/login")
        //    .then(res => {
        //        console.log(res);
        //        navigate("/admin");
        //    })
        //    .catch(err => {
        //        setBaddataDisplay("block");
        //        setErrorMessage(err.response.data);
        //    })
    }

    return (
        <div className={styles.container}>
            <img className={styles.img} src='../static/img/am_newlogo.png'/>
            <div className={styles.form}>
                <p className={styles.title}>Увійдіть в систему, щоб користуватися функціями адмін-панелі:</p>
                <form className={styles.login_form}>
                    <input type="text" placeholder="Логін" name='login' onChange={inputsHandle}/>
                    <input type="password" placeholder="Пароль" name='pwd' onChange={inputsHandle}/>
                    <p className={styles.warning} style={{display: baddataDisplay}}>{errorMessage}</p>
                    <button onClick={loginHandle}> Увійти в систему </button>
                </form>
            </div>
        </div>
    );}

export default Login;