import React from "react";
import {Link} from "react-router-dom";
import styles from "../AdminMain.module.css";
import AddIcon from '@mui/icons-material/Add';
import { useQuery } from "@tanstack/react-query";
import backendRequest from "../../../../axios";
import LoadingSpinner from "../../../Spinner";
import moment from "moment";
import parse from "html-react-parser";

function AdminUsers(props){
    const { isLoading, error, data, refetch } = useQuery(["users"], () =>
        backendRequest.get("/users").then((res) => {
            return res.data
      }));
    
    const activateUser = async (e, userId) => {
        e.preventDefault();
        await backendRequest.post("/users/" + userId + "/activate")
            .then(res => console.log(res))
            .catch(err => console.log(err));
        refetch();
    }

    return(
        <div className={styles.container}>
            {
                error
                ? error.response.data
                : isLoading
                ? <LoadingSpinner/>
                :
                <>
                    <Link className={styles.add_new} to='new' state={{isNew:true}}><AddIcon/>Додати користувача</Link>
                    <div className={styles.content}>
                        <div className={styles.table_row + ' ' + styles.table_header} key="0">
                            <div className={styles.table_cell}>
                                ID
                            </div>
                            <div className={styles.table_cell}>
                                Ім'я користувача
                            </div>
                            <div className={styles.table_cell}>
                                Електронна пошта
                            </div>
                            <div className={styles.table_cell}>
                                Статус
                            </div>
                            <div className={styles.table_cell}>
                                Останній час входу
                            </div>
                        </div>
                        {
                            data.map((user) =>
                            <div className={styles.table_row} key={user.id}>
                                <div className={styles.table_cell}>
                                    {user.id}
                                </div>
                                <div className={styles.table_cell}>
                                    {user.display_name}
                                </div>
                                <div className={styles.table_cell}>
                                    {user.email}
                                </div>
                                <div className={styles.table_cell + ' ' + styles.flexcell}>
                                    {user.activated
                                    ? "Активований" :
                                    <>
                                        Відключений
                                        <button className={styles.activate_button} onClick={(event) => activateUser(event, user.id)}>(Активувати)</button>
                                    </>}
                                </div>
                                <div className={styles.table_cell}>
                                    {moment(user.last_login_date).format("YYYY-MM-DD HH:mm:ss")}
                                </div>
                            </div>)
                        }
                    </div>
                </>
            }
            
        </div>
    )
}

export default AdminUsers