   import {Routes,Route} from "react-router-dom";
import React,{useState,useEffect} from 'react';
import styles from "./Main.module.css";
import Header from "../header/Header";
import MobileHeader from '../mobileHeader/MobileHeader'
import Welcome from "../welcome/Welcome";
import TeachersStaff from "../staff/TeachersStaff";
import TeacherPage from "../teachers/TeacherPage"
import Feedback from "../feedback/Feedback";
import Footer from "../footer/Footer"
import PostsBlock from "../postsBlock/PostsBlock"
import Gallery from "../gallery/Gallery";
import Companies from "../companies/Companies";
import Courses1 from "../courses/Courses1";
import Page404 from "../errorPages/Page404";
import History from "../history/History";
import Labs from "../labs/Labs";
import Department from "../department/Department";
import Post1 from "../post/Post1";
import Post from "../post/Post";
import EducationalPrograms from "../educationalPrograms/EducationalPrograms";
import Dual from "../dual/Dual";
import Invitation from "../invitation/Invitation";
import Graduates from "../graduates/Graduates";
import Course from "../courses/Course";
import data from '../courses/file (51).json'
import post from '../../components/post/file (34).json'
import dual from './file (53).json'
import magazine from './file (62).json'
import Heads from "../headsOfDepartment/Heads";
import Posts from "../posts/Posts";
import Excursion from "../excursion/Excursion";


function Main(props) {

    const [points,setPoints]=useState(window.innerWidth)

    const handleResize=()=>setPoints(window.innerWidth)

    useEffect(()=>{
        window.addEventListener('resize', handleResize)
        return ()=>window.addEventListener('resize',handleResize)
    })

    return (
        <>
            {points>740 ? <Header/> : <MobileHeader/>}
            {/*<Feedback/>*/}
            <div className={styles.wrapper}>
                <Routes>
                    <Route path='' element={<Welcome/>}/>
                    <Route path='staff/teachers' element={<TeachersStaff/>}/>
                    <Route path='teachers/:id' element={<TeacherPage/>}/>
                    <Route path='courses' element={<Courses1/>}/>
                    <Route path='fifty' element={<Gallery/>}/>
                    <Route path='galleries/:id' element={<Gallery/>}/>
                    <Route path='companies' element={<Companies/>}/>
                    <Route path='history' element={<History/>}/>
                    <Route path='labs' element={<Labs/>}/>
                    <Route path='department' element={<Department/>}/>
                    <Route path='posts' element={<Posts/>}/>
                    <Route path='posts/:id' element={<Post/>}/>
                    <Route path='programs' element={<EducationalPrograms/>}/>
                    <Route path='dual' element={<Dual/>}/>
                    <Route path='invitation' element={<Invitation/>}/>
                    <Route path='graduates' element={<Graduates/>}/>
                    <Route path='dual-education' element={<Post1 post={dual}/>}/>
                    <Route path='magazine' element={<Post1 post={magazine}/>}/>
                    <Route path='posts/:id' element={<Post/>}/>
                    {data.map(map=>{
                        return <Route key={map.id} path={`courses/${map.id}`} element={<Course name={map.name} course={map.courses} icons={map.icons}/>}/>
                    })}
                    <Route path='heads' element={<Heads/>}/>
                    <Route path='excursions' element={<Excursion/>}/>
                    <Route path='*' element={<Page404/>}/>
                </Routes>
            </div>
            <PostsBlock/>
            <Footer/>
        </>
    );
}

export default Main;