import React, { useState } from "react";
import styles from "./AdminSidebar.module.css";
import ArticleIcon from "@mui/icons-material/Article";
import GroupsIcon from "@mui/icons-material/Groups";
import MapIcon from "@mui/icons-material/Map";
import Person from "@mui/icons-material/Person";
import CollectionsIcon from '@mui/icons-material/Collections';
import PeopleIcon from '@mui/icons-material/People';
import {Link} from "react-router-dom";


function AdminSidebar(props){

    const [active, setActive] = useState(0);

    const handleClick = (e) => {
        setActive(e.target.id);
    }

    const handleSVGClick = (e) => {
        if (e.target.tagName === "svg") setActive(e.target.parentElement.id);
        else if (e.target.tagName === "path") setActive(e.target.parentElement.parentElement.id);
    }

    const items = [
        {id:1, label:"Новини", icon: <ArticleIcon onClick={handleSVGClick}/>, link:"posts"},
        {id:2, label:"Викладачі", icon: <GroupsIcon onClick={handleSVGClick}/>, link:"teachers"},
        {id:3, label:"Предмети", icon:<PeopleIcon onClick={handleSVGClick}/>, link:"subjects"},
        {id:4, label:"Галереї", icon:<CollectionsIcon onClick={handleSVGClick}/>, link:"galleries"},
        {id:5, label:"Карти курсів", icon: <MapIcon onClick={handleSVGClick}/>, link:"coursesmaps"},
        {id:6, label:"Користувачі", icon:<Person onClick={handleSVGClick}/>, link:"users"},
    ]

    return(
        <div className={styles.sidebar}>
            {items.map((item) => <Link className={active == item.id ? styles.item + ' ' + styles.active : styles.item} to={item.link} id={item.id} key={item.id} onClick={handleClick}>
                {item.icon}
                {item.label}
            </Link>)}
        </div>
    )
}

export default AdminSidebar