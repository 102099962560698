import React,{useState} from "react";
import styles from './Dual.module.css'

function Dual(props){

    const [state,setState]=useState([false,false])

    return(
        <>
            <div className={styles.title}>На кафедрі започатковано програму подвійних дипломів з італійським університетом</div>
            <div className={styles.main}>
                <img className={state[0] ? styles.active : styles.img} onClick={()=>setState([!state[0],false])} alt='Applied Mathematics' src="static/img/dual_full.png"/>
                <div>Національний університет «Львівська політехніка» та <a href="https://www.univaq.it/en/index.php">University of L’Aquila, Італія</a> , підписали угоду про програму подвійних дипломів магістра, відповідно до якої студенти-політехніки спеціальностей «Прикладна математика» та «Математичне та комп’ютерне моделювання» мають можливість два семестри навчатися в Італії та одночасно з українським дипломом магістра отримати італійський диплом магістра із «Математичної інженерії».</div>
                <div>Львівська політехніка стала учасником міжнародного Консорціуму RealMaths, який об’єднує 17 університетів України, Італії, Канади, Польщі, Німеччини, В’єтнаму та Гани і метою якого є спільна підготовка фахівців із напряму Mathematics for Real World Applications.</div>
            </div>
            
        </>
        
    )
}

export default Dual