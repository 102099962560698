import React,{useState} from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded';
import FunctionsRoundedIcon from '@mui/icons-material/FunctionsRounded';
import LaptopRoundedIcon from '@mui/icons-material/LaptopRounded';
import SchemaRoundedIcon from '@mui/icons-material/SchemaRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import styles from './Course.module.css'

function Course({name,course,icons}){

    const [state,setState]=useState(0)
    const defaultAnot=[[[false,false],[false,false],[false,false],[false,false],[false,false],[false,false],[false,false],[false,false]],
    [[false,false],[false,false],[false,false],[false,false],[false,false],[false,false],[false,false],[false,false]]]
    const [anot,setAnot]=useState(defaultAnot)
    

    const next=()=>{
        if(state!==course.length-2){
            setState(prev => prev + 2)
        }
        else{
            setState(0)
        }
    }
    const next_mob=()=>{
        if(state!==course.length-1){
            setState(prev => prev + 1)
        }
        else{
            setState(0)
        }
    }

    const previous=()=>{
        if(state!==0){
            setState(prev => prev - 2)
        }
        else{
            setState(course.length-2)
        }
    }
    const previous_mob=()=>{
        if(state!==0){
            setState(prev => prev - 1)
        }
        else{
            setState(course.length-1)
        }
    }

    const anotation=(table,split,id)=>{

        const newArr = [[[false,false],[false,false],[false,false],[false,false],[false,false],[false,false],[false,false],[false,false]],
            [[false,false],[false,false],[false,false],[false,false],[false,false],[false,false],[false,false],[false,false]]]
        newArr[table][id][split]=!anot[table][id][split]
        setAnot(newArr)

    }

    const iconss=[
        [<LibraryBooksRoundedIcon/>,<PsychologyRoundedIcon/>,<FunctionsRoundedIcon/>,<LaptopRoundedIcon/>],
        [<LibraryBooksRoundedIcon/>,<PsychologyRoundedIcon/>,<TrendingUpRoundedIcon/>,<SchemaRoundedIcon/>]
        
    ]

    const number=[0,1,2,3,4,5]

    const colors=[
        {backgroundColor:'lightgray',color:'rgb(49,47,133)'},{backgroundColor:'rgb(49,47,133)',color:'white'},{backgroundColor:'rgb(0,0,60)',color:'white'},{backgroundColor:'rgb(99,97,183)',color:'white'}
    ]

    return(
        <>
        <div className={styles.header}>
            <p className={styles.header_title}> {name} </p>
            Умовні позначення:
            <div className={styles.marking}>
                <div  className={styles.marking_group}>
                    <div><LibraryBooksRoundedIcon/> - Предмет загальної підготовки</div>
                    <div><PsychologyRoundedIcon/> - Предмет професійної підготовки</div>
                    {icons.name==='pm' ? 
                        <>
                            <div><FunctionsRoundedIcon/> - <p> Дисципліна спеціалізації "Прикладна математика"</p></div>
                            <div><LaptopRoundedIcon/> - <p> Дисципліна спеціалізації "Інформатика"</p></div>
                        </>    
                        :
                        <>
                            <div><TrendingUpRoundedIcon/> - <p> Дисципліна вибіркового блоку "Актуарна та фінансова математика"</p></div>
                            <div><SchemaRoundedIcon/> - <p> Дисципліна вибіркового блоку "Моделювання фінансових інструментів"</p></div>
                        </>
                }
                </div>
                {/* <div className={styles.marking_group}>
                    <div><LibraryBooksRoundedIcon/> - Не пов'язано безпосередньо з IT</div>
                    <div><PeopleRoundedIcon/> - Можуть бути залучені фахівці з IT-сфери</div>
                    <div><PsychologyRoundedIcon/> - Предмет професійної підготовки</div>
                </div>
                <div className={styles.marking_group}>
                    <div><FunctionsRoundedIcon/> - Дисципліна спеціалізації "Прикладна математика"</div>
                    <div><LaptopRoundedIcon/> - Дисципліна спеціалізації "Прикладна інформатика"</div>
                    <div><SchoolRoundedIcon/> - Бакалаврська кваліфікаційна робота</div>
                </div> */}
            </div>
            <div>Для перегляду анотації курсу, натисніть на назву курсу</div>
        </div>
        {window.innerWidth>740 ? 
        <div className={styles.main}>
            <NavigateBeforeIcon className={styles.arrow} onClick={()=>{previous();setAnot(defaultAnot)}}/>
            <div className={styles.semestr}>
                <div className={styles.title}>{state+1} семестр</div>
                <div  className={styles.table}>
                    <div className={styles.mark}></div>
                    <div  className={styles.sub} 
                    style={{color:'rgb(49,47,133)',borderBottom:'2px solid rgb(49,47,133)'}}>Предмет</div>
                    <div  className={styles.cred} style={{color:'rgb(49,47,133)'}}>Кредити</div>
                        {
                        course[state].map((el,index)=>
                            {
                                if(el.name!==undefined){
                                    return(
                                    <>
                                        <div className={styles.mark} style={{color:'rgb(49,47,133)'}}>
                                            {iconss[icons.id][el.color-1]}</div>
                                        <div className={styles.sub} onClick={()=>anotation(0,0,el.id)}
                                         style={colors[el.color-1]}>{el.name}{anot[0][el.id][0] && <div 
                                         style={{display:'inline',paddingLeft:'10px',color:'rgb(175,175,175)'}}>
                                            {el.anot}</div>}</div>
                                        <div className={styles.cred} style={{color:'rgb(49,47,133)'}}>
                                            {el.credits}</div>
                                    </> )
                                }
                                else{
                                    return(
                                        <>
                                            <div className={styles.tab1} style={anot[0][el.id][0]+anot[0][el.id][1] ? {alignContent:'space-between'}:{}}>
                                                <div style={{color:'rgb(49,47,133)'}}>{iconss[icons.id][2]}</div>
                                                <div style={{color:'rgb(49,47,133)'}}>{iconss[icons.id][3]}</div>
                                            </div>
                                            <div className={styles.tab1}>
                                                <div className={styles.tab1sub} onClick={()=>anotation(0,0,el.id)} style={colors[2]}>{el.firstname}{anot[0][el.id][0] && <div style={{display:'inline',color:'rgb(175,175,175)'}}>{el.firstanot}</div>}</div>
                                                <div className={styles.tab1sub} onClick={()=>anotation(0,1,el.id)} style={colors[3]}> {el.secondname}{anot[0][el.id][1] && <div style={{display:'inline',color:'rgb(175,175,175)'}}>{el.secondanot}</div>}</div>
                                            </div>
                                            <div className={styles.cred} style={{color:'rgb(49,47,133)'}}>
                                                {el.credits}</div>
                                        </>
                                    )
                                }
                            
                            })
                        }
                    
                </div>
            </div>
            <div className={styles.semestr}>
                <div className={styles.title}>{state+2} семестр</div>
                <div  className={styles.table}>
                <div className={styles.mark}></div>
                <div  className={styles.sub} style={{color:'rgb(49,47,133)',borderBottom:'2px solid rgb(49,47,133)'}}>Предмет</div>
                    <div  className={styles.cred} style={{color:'rgb(49,47,133)'}}>Кредити</div>
                        {
                        course[state+1].map(el=>
                            {
                                if(el.name!==undefined){
                                    return(
                                    <>
                                        <div className={styles.mark} style={{color:'rgb(49,47,133)'}}>{iconss[icons.id][el.color-1]}</div>
                                        <div className={styles.sub} onClick={()=>anotation(1,0,el.id)} style={colors[el.color-1]}>{el.name}{anot[1][el.id][0] && <div style={{display:'inline',paddingLeft:'10px',color:'rgb(175,175,175)'}}>{el.anot}</div>}</div>
                                        <div className={styles.cred} style={{color:'rgb(49,47,133)'}}>{el.credits}</div>
                                    </> )
                                }
                                else{
                                    return(
                                        <>
                                            <div className={styles.tab1} style={anot[1][el.id][0]+anot[1][el.id][1] ? {alignContent:'space-between'}:{}}>
                                                <div style={{color:'rgb(49,47,133)'}}>{iconss[icons.id][2]}</div>
                                                <div style={{color:'rgb(49,47,133)'}}>{iconss[icons.id][3]}</div>
                                            </div>
                                            <div className={styles.tab1}>
                                                <div className={styles.tab1sub} onClick={()=>anotation(1,0,el.id)} style={colors[2]}>{el.firstname}{anot[1][el.id][0] && <div style={{display:'inline',color:'rgb(175,175,175)'}}>{el.firstanot}</div>}</div>
                                                <div className={styles.tab1sub} onClick={()=>anotation(1,1,el.id)}  style={colors[3]}>{el.secondname}{anot[1][el.id][1] && <div style={{display:'inline',color:'rgb(175,175,175)'}}>{el.secondanot}</div>}</div>
                                            </div>
                                            <div className={styles.cred} style={{color:'rgb(49,47,133)'}}>{el.credits}</div>
                                        </>
                                    )
                                }
                            
                            })
                        }
                    
                </div>
            </div>
            <NavigateNextIcon className={styles.arrow} onClick={()=>{next();setAnot(defaultAnot)}}/>
        </div>
        :
        <div className={styles.main}>
        <NavigateBeforeIcon className={styles.arrow} onClick={()=>{previous_mob();setAnot(defaultAnot)}}/>
        <div className={styles.semestr}>
            <div className={styles.title}>{state+1} семестр</div>
            <div  className={styles.table}>
                <div className={styles.mark}></div>
                <div  className={styles.sub} 
                style={{color:'rgb(49,47,133)',borderBottom:'2px solid rgb(49,47,133)'}}>Предмет</div>
                <div  className={styles.cred} style={{color:'rgb(49,47,133)'}}>Кредити</div>
                    {
                    course[state].map(el=>
                        {
                            if(el.name!==undefined){
                                return(
                                <>
                                    <div className={styles.mark} style={{color:'rgb(49,47,133)'}}>
                                        {iconss[icons.id][el.color-1]}</div>
                                    <div className={styles.sub} onClick={()=>anotation(0,0,el.id)}
                                     style={colors[el.color-1]}>{el.name}{anot[0][el.id][0] && <div 
                                     style={{display:'inline',paddingLeft:'10px',color:'rgb(175,175,175)'}}>
                                        {el.anot}</div>}</div>
                                    <div className={styles.cred} style={{color:'rgb(49,47,133)'}}>
                                        {el.credits}</div>
                                </> )
                            }
                            else{
                                return(
                                    <>
                                        <div className={styles.tab1} style={anot[0][el.id][0]+anot[0][el.id][1] ? {alignContent:'space-between'}:{}}>
                                            <div style={{color:'rgb(49,47,133)'}}>{iconss[icons.id][2]}</div>
                                            <div style={{color:'rgb(49,47,133)'}}>{iconss[icons.id][3]}</div>
                                        </div>
                                        <div className={styles.tab1}>
                                            <div className={styles.tab1sub} onClick={()=>anotation(0,0,el.id)} style={colors[2]}>{el.firstname}{anot[0][el.id][0] && <div style={{display:'inline',color:'rgb(175,175,175)'}}>{el.firstanot}</div>}</div>
                                            <div className={styles.tab1sub} onClick={()=>anotation(0,1,el.id)} style={colors[3]}> {el.secondname}{anot[0][el.id][1] && <div style={{display:'inline',color:'rgb(175,175,175)'}}>{el.secondanot}</div>}</div>
                                        </div>
                                        <div className={styles.cred} style={{color:'rgb(49,47,133)'}}>
                                            {el.credits}</div>
                                    </>
                                )
                            }
                        
                        })
                    }
                
            </div>
        </div>
            <NavigateNextIcon className={styles.arrow} onClick={()=>{next_mob();setAnot(defaultAnot)}}/>
        </div>
        }
        </>
    )
}

export default Course