import React, { useState } from 'react';
import styles from "./TeacherPage.module.css";
import parse from 'html-react-parser';

function TeacherPageDoubList({ list, title }) {
    const obj = JSON.parse(list);
    
    return (
        <details id={title} open>
            <summary className={styles.list_block_title}>{title}</summary>
            {
                obj.map((block, id) => 
                    <div key={id} className={styles.doub_list_block}>
                        <p className={styles.doub_list_left}>{parse(block.left)}</p>
                        <div className={styles.doub_list_right}>
                            {block.right.map((text, id) => <p key={id}>{parse(text)}</p>)}
                        </div>
                    </div>)
            }
        </details>
    );
}

export default TeacherPageDoubList;