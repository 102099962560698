import React, { useState } from "react";
import styles from "../AdminMain.module.css";
import AdminTeachersGrid from "./AdminTeachersGrid";

function AdminTeachers(props){
    const handleEditWindow = (e, component) =>{
        e.preventDefault();
        setPageComponent(component);
    }

    const [pageComponent, setPageComponent] = useState(<AdminTeachersGrid handleEdit={handleEditWindow}/>);

    return(
        <div className={styles.container}>
            {pageComponent}
        </div>
    )
}

export default AdminTeachers