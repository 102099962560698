import React, { useEffect, useRef, useState } from 'react';
import styles from "./TeacherCard.module.css"
import { Link } from 'react-router-dom';
import loadImage from '../../loadImage';

function TeacherCard({info}) {
    const [imgStyle, setImgStyle] = useState({});
    const ref = useRef();
    const imageExpandStyle = {
        width: "145px",
        height: "193.5px",
        transition: "ease 0.2s"
    };

    useEffect(() => {
        async function fetchImage() {
            await loadImage("small-logo_" + info.id, info.img_path);
        }

        if (ref.current && ref.current.complete) {
            fetchImage(ref.current);
          }
    });

    const PIB = info.full_name.toString().split(' ');

    return (
        <Link
         to={'/teachers/' + info.id}
         className={styles.link} 
         state={{id:info.id}}>
            <div
             className={styles.card}
             key={info.id}
             onMouseEnter={() => setImgStyle(imageExpandStyle) }
             onMouseLeave={() => setImgStyle({})}>
                <img
                 ref={ref}
                 id={"small-logo_" + info.id}
                 alt={info.title}
                 style={imgStyle}/>
                <div className={styles.info}>
                    <p>{PIB[0]} {PIB[1][0]}.{PIB[2][0]}.</p>
                </div>
            </div>
        </Link>
    );
}

export default TeacherCard;