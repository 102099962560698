import React, { useContext, useEffect } from "react";
import AdminUsers from "./users/AdminUsers";
import AdminUser from "./users/AdminUser";
import AdminTeachers from "./teachers/AdminTeachers";
import AdminTeacher from "./teachers/AdminTeacher";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import styles from "./AdminMain.module.css";
import { AuthContext } from "../../../context/userContext";
import AdminGalleries from "./galleries/AdminGalleries";
import AdminGallery from "./galleries/AdminGallery";
import NewPost from "./posts/NewPost";
import AdminUserFirstTimeForm from "./users/AdminUserFirstTimeForm";
import Posts from "./posts/Posts";
import Subject from "./subjects/Subject";
import Coursesmap from "./coursesmap/Coursesmap";
import AdminNewGallery from "./galleries/AdminNewGallery";
import ModifyPost from "./posts/ModifyPost";
import GetPost from "./posts/GetPost";

function AdminMain(){
    const {currentUser, logout} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if(!currentUser)
        {
            navigate("/admin/login");
        }
        else if(currentUser.firstLogin)
        {
            navigate("/admin/users/first-login");
        }
    }, [currentUser]);

    return(
        <div className={styles.wrapper}>
            <div className={styles.wrapper_warning}>Якщо будь-яка операція адмін-панелі повертає Unauthorized (401), перезайдіть у систему - сплив термін токену доступу до серверу.</div>
            <div className={styles.wrapper_warning}>Адмін-панель знаходиться у активній розробці, тому деякі функції можуть бути відключені або виконуватись з неочікуваним результатом.</div>
            <div className={styles.wrapper_warning}>Будь ласка, регулярно робіть бекапи БД та її чистку від невдалих результатів використання адмін-панелі.</div>
            <Routes>
                <Route path ='/' element={<Navigate to='/admin/users'/>}/>
                <Route path ='posts' element={<Posts/>}/>
                <Route path ='posts/:id' element={<GetPost/>}/>
                <Route path ='posts/newPost' element={<NewPost/>}/>
                <Route path ='teachers' element={<AdminTeachers/>}/>
                <Route path ='teachers/new' element={<AdminTeacher/>}/>
                <Route path ='teachers/:id' element={<AdminTeacher/>}/>
                <Route path ='galleries' element={<AdminGalleries/>}/>
                <Route path ='galleries/:id' element={<AdminGallery/>}/>
                <Route path ='galleries/new' element={<AdminNewGallery/>}/>
                <Route path ='subjects' element={<Subject/>}/>
                <Route path ='coursesmaps' element={<Coursesmap/>}/>
                <Route path ='users' element={<AdminUsers/>}/>
                <Route path ='users/new' element={<AdminUser/>}/>
                <Route path ='users/first-login' element={<AdminUserFirstTimeForm/>}/>

            </Routes>
        </div>
    )
}

export default AdminMain