import React from 'react';
import styles from './Companies.module.css'

function Companies(props) {

    const list=[
        {name:'Facebook',src:'Facebook',link:''},
        {name:'Google',src:'Google',link:''},
        {name:'Amazon',src:'Amazon',link:''},
        {name:'ScotiaBank',src:'Scotiabank',link:''},
        {name:'Sabadell united bank',src:'Sabadell',link:''},
        {name:'Salesforce',src:'Salesforce',link:''},
        {name:'MassMutual Financial Group',src:'MassMutual',link:''},
        {name:'SoftServe',src:'SoftServe',link:''},
        {name:'N-iX',src:'N-iX',link:''},
        {name:'Sigma Software',src:'SigmaSoftware',link:''},
        {name:'Binary STUDIO',src:'BinarySTUDIO',link:''},
        {name:'Global Logic',src:'GlobalLogic',link:''},
        {name:'CoreValue',src:'CoreValue',link:''},
        {name:'ELEKS',src:'ELEKS',link:''},
        {name:'EPAM',src:'EPAM',link:''},
        {name:'Inspirito',src:'Inspirito',link:''},
        {name:'Intelex',src:'Intelex',link:''},
        {name:'Intellias',src:'Intellias',link:''},
        {name:'ITERA',src:'ITERA',link:''},
        {name:'Lohika',src:'Lohika',link:''},
        {name:'RedTag',src:'RedTag'},
        {name:'Avenga',src:'Avenga',link:''},
        {name:'Relevant',src:'Relevant',link:''},
        {name:'STEP',src:'STEP',link:''},
        {name:'Symphony Solution',src:'SymphonySolutions',link:''},
        {name:'tdb',src:'tdb',link:''},
    ]

    return (
        <div className={styles.main}>

            <div className={styles.logos}>
                <h1 className={styles.h1}>Наші випускники працюють у таких компаніях:</h1>
                {list.map(
                    (element)=><a key={element.name} src={element.link}><img  className={styles.logo} src={`static/companies/${element.src}.png`} alt={element.name}/></a>
                )}
            </div>
        </div>
    );
}

export default Companies;