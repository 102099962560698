import React from "react";
import styles from './Posts.module.css'
import backendRequest from "../../../../axios";
import { useQuery } from "@tanstack/react-query";
import {Link} from "react-router-dom";

function Posts(props){

    const { isLoading, error, data, refetch } = useQuery(["posts"], () =>
        backendRequest.get("/posts").then((res) => {
            return res.data
      }));

    const check = async (e,id,value)=>{
        e.preventDefault()
        await backendRequest.post('/posts/' + id + '/publish',{published:value === 1 ? false : true})
        refetch()
    }

    return(
        <div className={styles.table}>
            <div className={styles.header}>
                <div className={styles.name}>Заголовок новини</div>
                <div className={styles.mod_title}>Редагування новини</div>
                <div>Публікація новини</div>
            </div>

            {data && data.map(el=>{
                return <div className={styles.row}>
                    <span className={styles.title}>{el.title}</span>
                    <Link className={styles.link_mod} to={`${el.id}`}><div className={styles.link_mod_text}>Редагувати</div></Link>
                    <button className={el.published ? styles.checkbox_hide : styles.checkbox_post} id={`input_${el.id}`} onClick={(e)=>check(e,el.id,el.published)}>{el.published ? 'Приховати' : 'Опублікувати'}</button>
                </div>
            })}
            <Link className={styles.link} to='newPost'><div className={styles.link_text}>Створити новину</div></Link>

        </div>
    )
}

export default Posts