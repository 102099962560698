import React, { useState } from 'react';
import styles from './PostsBlock.module.css'
import PostCard from '../postCard/PostCard';
import { useQuery } from '@tanstack/react-query';
import backendRequest from '../../axios';
import LoadingSpinner from '../Spinner';
import moment from 'moment';

const posts_plug = [
    {id: 1, img:"/static/img/am_logo-300x169.png", title: "Post title", text: "Fugiat nostrud irure laborum ipsum sit aute deserunt veniam voluptate labore. Qui in eiusmod labore laborum aute anim. Ut magna ullamco sit ea duis sint aliquip aliquip occaecat fugiat deserunt ex dolor exercitation. Adipisicing veniam laboris ex consequat non nulla tempor sunt eu sit ad commodo dolor. Ea laboris eu non nulla pariatur occaecat cillum."},
    {id: 2, img:"/static/img/am_logo-300x169.png", title: "Post title", text: "Fugiat nostrud irure laborum ipsum sit aute deserunt veniam voluptate labore. Qui in eiusmod labore laborum aute anim. Ut magna ullamco sit ea duis sint aliquip aliquip occaecat fugiat deserunt ex dolor exercitation. Adipisicing veniam laboris ex consequat non nulla tempor sunt eu sit ad commodo dolor. Ea laboris eu non nulla pariatur occaecat cillum."},
    {id: 3, img:"/static/img/am_logo-300x169.png", title: "Post title", text: "Fugiat nostrud irure laborum ipsum sit aute deserunt veniam voluptate labore. Qui in eiusmod labore laborum aute anim. Ut magna ullamco sit ea duis sint aliquip aliquip occaecat fugiat deserunt ex dolor exercitation. Adipisicing veniam laboris ex consequat non nulla tempor sunt eu sit ad commodo dolor. Ea laboris eu non nulla pariatur occaecat cillum."},
    {id: 4, img:"/static/img/am_logo-300x169.png", title: "Post title", text: "Fugiat nostrud irure laborum ipsum sit aute deserunt veniam voluptate labore. Qui in eiusmod labore laborum aute anim. Ut magna ullamco sit ea duis sint aliquip aliquip occaecat fugiat deserunt ex dolor exercitation. Adipisicing veniam laboris ex consequat non nulla tempor sunt eu sit ad commodo dolor. Ea laboris eu non nulla pariatur occaecat cillum."},
    {id: 5, img:"/static/img/am_logo-300x169.png", title: "Post title", text: "Fugiat nostrud irure laborum ipsum sit aute deserunt veniam voluptate labore. Qui in eiusmod labore laborum aute anim. Ut magna ullamco sit ea duis sint aliquip aliquip occaecat fugiat deserunt ex dolor exercitation. Adipisicing veniam laboris ex consequat non nulla tempor sunt eu sit ad commodo dolor. Ea laboris eu non nulla pariatur occaecat cillum."},
    {id: 6, img:"/static/img/am_logo-300x169.png", title: "Post title", text: "Fugiat nostrud irure laborum ipsum sit aute deserunt veniam voluptate labore. Qui in eiusmod labore laborum aute anim. Ut magna ullamco sit ea duis sint aliquip aliquip occaecat fugiat deserunt ex dolor exercitation. Adipisicing veniam laboris ex consequat non nulla tempor sunt eu sit ad commodo dolor. Ea laboris eu non nulla pariatur occaecat cillum."}
]

function PostsBlock() {
    const { isLoading, error, data } = useQuery(["postsBlock"], () => {
        return backendRequest.get("/posts").then(res => {
            return res.data.filter(post => post.published === 1).sort((a, b) => new moment(a.created_date).format('YYYYMMDD') - new moment(b.created_date).format('YYYYMMDD')).reverse().slice(0, 6);
        });
    });


    return (
        <div className={styles.selector}>
            <p className={styles.selector_header}>Останні новини:</p>
            {
                isLoading
                ? <LoadingSpinner/>
                : error
                ? "Щось пішло не так!"
                :<div className={styles.grid}>
                {
                    data.map(post => (<PostCard key={post.id} info={post}/>))
                }
                </div>
            }
            <a href='/posts' className={styles.selector_footer}>Переглянути всі новини</a>
        </div>
    );
}

export default PostsBlock;