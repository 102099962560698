import React,{useState,useEffect} from 'react'
import styles from './NewPost.module.css'
import backendRequest from "../../../../axios";
import autosize from "../../../../autosize";

function NewPost(props){

    const [post, setPost] = useState({
        title: '',
        paragraph: [
        ],

      })

      const [end,setEnd]=useState(false)

      const loadAutosize = (event, rowCount) =>
    {
        autosize(event.target.id, rowCount);
    }

    // useEffect(()=>{},[post])
    const [checkbox,setCheckbox]=useState([false,false,false,false])

    const checkboxChange=(id)=>{
        let check = [...checkbox] // Створюємо новий масив на основі checkbox
        check[id] = !check[id]
        setCheckbox(check)
    }

    const newParagraph = (event) => {
        event.preventDefault()
        const newPar = {}
        if(post.paragraph.length===0) newPar.id=0
        else newPar.id=post.paragraph[post.paragraph.length-1].id+1
        newPar.modifiers=[]
        if (checkbox[0]) {newPar.title = ' ';newPar.modifiers.push({obj:'title',arr:[]})}
        if (checkbox[1]) {newPar.text = ' ';newPar.modifiers.push({obj:'text',arr:[]})}
        if (checkbox[2]) {newPar.list = [{id:0,text:' '}];newPar.modifiers.push({obj:'list',arr:[{id:0,arr:[]}]})}
        if (checkbox[3]) newPar.img = {}

        setPost((prevPost) => ({
        ...prevPost,
        paragraph: [...prevPost.paragraph, newPar]
        }))
    }

    const titleChange=(e,id)=>{
        const newArr=post.paragraph.map(el=>
            {
                if(el.id === id){
                    return {...el,title:e.target.value}
                }
                return el
            })
        setPost((prevPost)=>({
            ...prevPost,
            paragraph:newArr
        }))
    }

    const textChange=(e,id)=>{
        const newArr=post.paragraph.map(el=>
            {
                if(el.id === id){
                    return {...el,text:e.target.value}
                }
                return el
            })
        setPost((prevPost)=>({
            ...prevPost,
            paragraph:newArr
        }))
    }

    const newItem=(event,id)=>{
        event.preventDefault()
        let item_id
        let item={}
        let lists=[]
        const paragraphs=post.paragraph.map(el=>{
            if(el.id===id){
                item_id=el.list[el.list.length-1].id+1
                item.id=item_id
                item.text=' '
                lists=[...el.list,item]
                return{...el,list:lists}
            }
            return el
        })

        setPost((prevPost) => ({
        ...prevPost,
        paragraph: paragraphs
        }))

        setPost(prevPost => {
            const updatedParagraphs = prevPost.paragraph.map(paragraph => {
              if (paragraph.id == id) {
                const updatedModifiers = paragraph.modifiers.map(modifier => {
                  if (modifier.obj === 'list') {
                    return {
                      ...modifier,
                      arr: [...modifier.arr, {id:item_id,arr:[]}]
                    };
                  }
                  return modifier;
                });
                return {
                  ...paragraph,
                  modifiers: updatedModifiers
                };
              }
              return paragraph;
            });
            return {
              ...prevPost,
              paragraph: updatedParagraphs
            };
          });
    }

    const listChange=(e,id,list_id)=>{
        const newArr=post.paragraph.map(el=>
            {
                if(el.id == id){
                    const newList=el.list.map(elem=>{
                        if(elem.id === list_id){
                            return {...elem,text:e.target.value}
                        }
                        return elem
                    })
                    return {...el,list:newList}
                }
                return el
            })
        setPost((prevPost)=>({
            ...prevPost,
            paragraph:newArr
        }))
    }
    const imgChange=async (e,id)=>{
      let formData = new FormData();
              formData.append("file", e.target.files[0]);
              const path = await backendRequest.post('/upload',formData)
              //console.log(path)
      const newArr=post.paragraph.map(el=>
        {
            if(el.id === id){
              const newImg=el.img
              newImg.link=path.data
              return {...el,img:newImg}
            }
            return el
        })
        //console.log(newArr)
      setPost((prevPost)=>({
          ...prevPost,
          paragraph:newArr
      }))
    }

    const imgAnotChange=(e,id)=>{
      const newArr=post.paragraph.map(el=>
        {
            if(el.id === id){
              const newImg=el.img
              newImg.anotation=e.target.value
              return {...el,img:newImg}
            }
            return el
        })
      setPost((prevPost)=>({
          ...prevPost,
          paragraph:newArr
      }))
      //console.log(post)
    }


    const boldSelection=(id,obj)=>{

        const select = window.getSelection()
        setPost(prevPost => {
            const updatedParagraphs = prevPost.paragraph.map(paragraph => {
              if (paragraph.id === id) {
                const updatedModifiers = paragraph.modifiers.map(modifier => {
                  if (modifier.obj === obj) {
                    return {
                      ...modifier,
                      arr: [...modifier.arr, [select.anchorOffset<select.extentOffset ? select.anchorOffset : select.extentOffset,select.toString().length]]
                    };
                  }
                  return modifier;
                });
                return {
                  ...paragraph,
                  modifiers: updatedModifiers
                };
              }
              return paragraph;
            });
            return {
              ...prevPost,
              paragraph: updatedParagraphs
            };
          });
    }
    const boldListSelection=(id,obj,id_par)=>{

        const select = window.getSelection()
        setPost(prevPost => {
            const updatedParagraphs = prevPost.paragraph.map(paragraph => {
              if (paragraph.id === id) {
                const updatedModifiers = paragraph.modifiers.map(modifier => {
                  if (modifier.obj === obj) {
                    const list=modifier.arr.map(arr=>{
                        if(arr.id===id_par){
                            return{
                                ...arr,
                                arr:[...arr.arr,[select.anchorOffset<select.extentOffset ? select.anchorOffset : select.extentOffset,select.toString().length]]
                            }
                        }
                        return arr
                    })
                    return {
                      ...modifier,
                      arr: list
                    };
                  }
                  return modifier;
                });
                return {
                  ...paragraph,
                  modifiers: updatedModifiers
                };
              }
              return paragraph;
            });
            return {
              ...prevPost,
              paragraph: updatedParagraphs
            };
          });
    }
    const linkSelection=(id,obj)=>{

        const select = window.getSelection()
        //console.log(select)
        
        const link = prompt('Введіть адресу посилання: ')
        setPost(prevPost => {
            const updatedParagraphs = prevPost.paragraph.map(paragraph => {
              if (paragraph.id === id) {
                const updatedModifiers = paragraph.modifiers.map(modifier => {
                  if (modifier.obj === obj) {
                    return {
                      ...modifier,
                      arr: [...modifier.arr, [select.anchorOffset<select.extentOffset ? select.anchorOffset : select.extentOffset,select.toString().length,link]]
                    };
                  }
                  return modifier;
                });
                return {
                  ...paragraph,
                  modifiers: updatedModifiers
                };
              }
              return paragraph;
            });
            return {
              ...prevPost,
              paragraph: updatedParagraphs
            };
          });
    }
    const linkListSelection=(id,obj,id_par)=>{

        const select = window.getSelection()
        const link = prompt('Введіть адресу посилання: ')
        setPost(prevPost => {
            const updatedParagraphs = prevPost.paragraph.map(paragraph => {
              if (paragraph.id === id) {
                const updatedModifiers = paragraph.modifiers.map(modifier => {
                  if (modifier.obj === obj) {
                    const list=modifier.arr.map(arr=>{
                        if(arr.id===id_par){
                            return{
                                ...arr,
                                arr:[...arr.arr,[select.anchorOffset<select.extentOffset ? select.anchorOffset : select.extentOffset,select.toString().length,link]]
                            }
                        }
                        return arr
                    })
                    return {
                      ...modifier,
                      arr: list
                    };
                  }
                  return modifier;
                });
                return {
                  ...paragraph,
                  modifiers: updatedModifiers
                };
              }
              return paragraph;
            });
            return {
              ...prevPost,
              paragraph: updatedParagraphs
            };
          });
    }

    const number=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]

    const Save=async ()=>{    
        const arr=post.paragraph.map(par=>{
          const updMod=par.modifiers.map(mod=>{
                const modd=[]
                mod.arr.map(elem=>{
                  if(mod.obj=='title' || mod.obj=='text'){
                    // 
                    modd.push(elem[0])
                  }
                  else {
                  const mod_arr=[]
                  elem.arr.map(el=>mod_arr.push(el[0]))
                  modd.push(mod_arr)
                  }})
                return{...mod,arr:modd}
                
                
            })
            return{...par,modifiers:updMod}
        })
        await backendRequest.post('/posts',{title:post.title,description:JSON.stringify(post.paragraph)})
        const back = document.createElement('a');
        back.href='/admin/posts'
        back.click()
    }

    return(
        <>
        {!end && (
             <div className={styles.main}>
              <div className={styles.title}>
                Заголовок новини:
                <input type='text' maxLength='255' onChange={(e)=>{setPost((prev)=>({...prev,title:e.target.value}))}}></input>
              </div>
             <form className={styles.form} onSubmit={newParagraph}>
                 <label>
                     Вмістиме абзацу:
                     <div>
                         Заголовок:
                         <input type="checkbox" value='текст' onChange={()=>checkboxChange(0)}/>
                     </div>
                     <div>
                         Teкст:
                         <input type="checkbox" value='текст' onChange={()=>checkboxChange(1)}/>
                     </div>
                     <div>
                         Список вкінці абзацу:
                         <input type="checkbox" value='текст' onChange={()=>checkboxChange(2)}/>
                     </div>
                     <div>
                         Зображення:
                         <input type="checkbox" value='текст' onChange={()=>checkboxChange(3)}/>
                     </div>
                     <input className={styles.form_input} type="submit" value='Добавити абзац'/>
                 </label>
 
             </form>
             {post.paragraph.map(el=>{
 
                 return(
                     <div className={styles.abzats} key={el.id}>
                          {el.id + 1} абзац:
                         {el.title && <div>Заголовок:<input className={styles.subtitle} onChange={e=>titleChange(e,el.id)} type='text'></input></div>}
                         {el.text && <textarea id={`text_${el.id}`} placeholder='Текст абзацу' onChange={e=>textChange(e,el.id)} onKeyUp={(event => loadAutosize(event, 7))}></textarea>}
                         {el.list && <div>{el.list.map(element=>{
                             return(<textarea id={`text_${el.id}_${element.id}`} placeholder={`${element.id+1}-й пункт списку`} onKeyUp={(event => loadAutosize(event, 7))} onChange={e=>listChange(e,el.id,element.id)}/>)
                         })}<button onClick={(e)=>newItem(e,el.id)}>add new item to list</button></div>
                         }
                         {el.img && <div><input onChange={e=>imgChange(e,el.id)} type="file" accept="image/jpeg, image/jpg, image/png"/><br/><input onChange={e=>imgAnotChange(e,el.id)} type="text" placeholder='Опис фотографії'/></div>}
                     </div>
                 )
             })}
             <div className={styles.button}><button onClick={()=>setEnd(true)}>Завершити та перейти до редагування окремих частин тексту</button></div>
             </div>
        )}
           {end && (
            <div className={styles.post}>
            
            <div className={styles.main_title} dangerouslySetInnerHTML={{__html: post.title}}/>
            <div className={styles.main1}>
                {post.paragraph.map(el=>{
                    return(
                        <>
                            {el.title && <><div className={styles.title1} dangerouslySetInnerHTML={{__html: el.title}}></div><button className={styles.zh} onClick={()=>boldSelection(el.id,'title')}>bold</button><button className={styles.link} onClick={()=>linkSelection(el.id,'title')}>link</button></>}
                            {el.text && <><div className={styles.text} dangerouslySetInnerHTML={{__html: el.text}}></div><button className={styles.zh} onClick={()=>boldSelection(el.id,'text')}>bold</button><button className={styles.link} onClick={()=>linkSelection(el.id,'text')}>link</button></>}
                            {el.list && <ul>
                                {el.list.map(elem=><><li dangerouslySetInnerHTML={{__html: elem.text}}></li><button className={styles.zh} onClick={()=>boldListSelection(el.id,'list',elem.id)}>bold</button><button className={styles.link} onClick={()=>linkListSelection(el.id,'list',elem.id)}>link</button></>)}
                                </ul>}
                            
                        </>
                    )
                })}
                <div className={styles.save}><button onClick={()=>Save()}>Закінчити та зберегти</button></div>
            </div>

            
            </div>
           )}
                
        </>
    )
}

export default NewPost