import React, { useEffect, useState } from "react";
import styles from './Post.module.css'
import post from './file (34).json'
import { useLocation } from "react-router-dom";
import backendRequest from "../../axios";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../Spinner";
import Page404 from "../errorPages/Page404";

function Post({post}){
    //const [fal, setFal] = useState([]);
    //const [state,setState]=useState(fal)
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        heigth: window.innerHeight
    })

    post.paragraph.map(par=>{
        par.modifiers.map(mod=>{
            mod.arr.sort((a,b)=>{return a[0]-b[0]})
    })})

    // const {pathname} = useLocation();
    
    // const parsedPath = pathname.split('/');
    // const postId = parsedPath[parsedPath.length - 1];

    // const handleResize = () => {
    //     setDimensions({
    //         width: window.innerWidth,
    //         height: window.innerHeight,
    //     });
    //     window.removeEventListener("resize", handleResize, false);
    // }

    // useEffect(() => {
    //     window.addEventListener("resize", handleResize, false);
    // }, []);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setDimensions({
    //             width: window.innerWidth,
    //             height: window.innerHeight,
    //         })
    //     }, 100);
    // }, [postId]);

    // useEffect(() => {
    //     post && post.paragraph.map(el=>{
    //         if (el && el.img) {
    //             const img = document.getElementById('img_' + el.id);
    //             img.dispatchEvent(new Event('load'));
    //         }
    //     });
    // }, [dimensions])

    // const { isLoading, error, data: post, refetch } = useQuery(["getPostByIdPublic", postId], () => {
    //     return backendRequest.get("/posts/" + postId)
    //         .then((res) =>
    //         {
    //             const parsedDescription = JSON.parse(res.data[0].description);
    //             parsedDescription.map(par => {
    //                 par.modifiers.map(mod => {
    //                     //console.log(Array.isArray(mod.arr[0]));
    //                     //console.log(mod.arr[0]);
    //                     mod.arr.sort((a,b)=> {
    //                         return a[0] - b[0];
    //                     });
    //                 })
    //             });
    //             setFal(parsedDescription.map(a => false));
    //             return {title: res.data[0].title, paragraph: parsedDescription};
    //         });
    // })

    const number=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
    
    const fal=[]
    for(let i=0;i<post.paragraph.length;i++){
        fal.push(false)
    }
    const [state,setState]=useState(fal)

    const Click=(id)=>{
        const arr=[]
        for(let i=0;i<post.paragraph.length;i++){
            arr.push(false)
        }
        arr[id]=!state[id]
        setState(arr)

        const img=document.getElementById(`img_${id}`)
        if((img.naturalWidth/img.naturalHeight)>(window.innerWidth/window.innerHeight)){
            if(state[id]){
                img.classList.remove(styles.width_active)
                img.classList.add(styles.width_img)
            }
            else{
                img.classList.remove(styles.width_img)
                img.classList.add(styles.width_active)
            }
        }
        else{
            if(state[id]){
                img.classList.remove(styles.height_active)
                img.classList.add(styles.height_img)
            }
            else{
                img.classList.remove(styles.height_img)
                img.classList.add(styles.height_active)
            }
        }
    }

    const imgStyle=(id)=>{
    // const imgStyle=(id, link)=>{
        const img=document.getElementById(`img_${id}`)

        // backendRequest.get("/download/" + link, {responseType:"blob"})
        //     .then((res) => {
        //         var fr = new FileReader();
        //         fr.onload = () => {
        //             img.src = fr.result;
        //         }
        //         fr.readAsDataURL(res.data);
        //     })

        if((img.naturalWidth/img.naturalHeight)>(window.innerWidth/window.innerHeight)){
            img.classList.add(styles.width_img)
        }
        else{
            img.classList.add(styles.height_img)
        }
    }
    
    return(
        <>
        {/* {
            error
            ? error.response.status = 404 ? <Page404/> : "Щось пішло не так!"
            : isLoading
            ? <LoadingSpinner/>
            : */}
            <div>
            <div className={styles['main_title']} dangerouslySetInnerHTML={{__html: post.title}}/>
                {
                    post.paragraph.map(el=>{
                    return(
                        <div className={styles.main}>
                        {
                            el && el.modifiers.map(element=>{
                                return(
                                    <div className={styles[element.obj]}>
                                        {element.obj=='list' ?
                                        <ul>
                                        {number.map(list_elem=>{
                                            if(element.arr[list_elem]!=undefined){
                                                return(
                                                    <li>
                                                    { element.arr[list_elem].length!=0 &&
                                                    // <span dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(0,element.arr[list_elem][0])}}/>}
                                                    <span dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(0,element.arr[list_elem][0][0])}}/>}
                                        {
                                            number.map(elem=>{
                                                if(element.arr[list_elem][elem]!=undefined){
                                                    if(elem==0){
                                                        if(element.arr[list_elem][elem].length==2){
                                                            return(
                                                                <>
                                                                    <span className={styles.bold} dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][elem][0],element.arr[list_elem][elem][0]+element.arr[list_elem][elem][1])}}/>
                                                                    {element.arr[list_elem][elem+1]!=undefined && <span dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][elem][0]+element.arr[list_elem][elem][1],element.arr[list_elem][elem+1][0])}}/>}
                                                                </>
                                                            )
                                                            
                                                        }
                                                        else{
                                                            return(
                                                                <>
                                                                    <a className={styles.link} href={element.arr[list_elem][elem][2]} dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][elem][0],element.arr[list_elem][elem][0]+element.arr[list_elem][elem][1])}}/>
                                                                    {element.arr[list_elem][elem+1]!=undefined && <span dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][elem][0]+element.arr[list_elem][elem][1],element.arr[list_elem][elem+1][0])}}/>}
                                                                </>
                                                            )
                                                            
                                                        }
                                                    }
                                                    if(element.arr[list_elem][elem-1][0]+element.arr[list_elem][elem-1][1]<=element.arr[list_elem][elem][0]){
                                                        if(element.arr[list_elem][elem].length==2){
                                                            return(
                                                                <>
                                                                    <span className={styles.bold} dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][elem][0],element.arr[list_elem][elem][0]+element.arr[list_elem][elem][1])}}/>
                                                                    {element.arr[list_elem][elem+1]!=undefined && <span dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][elem][0]+element.arr[list_elem][elem][1],element.arr[list_elem][elem+1][0])}}/>}
                                                                </>
                                                            )
                                                            
                                                        }
                                                        else{
                                                            return(
                                                                <>
                                                                    <a className={styles.link} href={element.arr[list_elem][elem][2]} dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][elem][0],element.arr[list_elem][elem][0]+element.arr[list_elem][elem][1])}}/>
                                                                    {element.arr[list_elem][elem+1]!=undefined && <span dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][elem][0]+element.arr[list_elem][elem][1],element.arr[list_elem][elem+1][0])}}/>}
                                                                </>
                                                            )
                                                            
                                                        }
                                                    }
                                                    else{
                                                        if(element.arr[list_elem][elem].length==2){
                                                            return(
                                                                <>
                                                                    <span dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][elem-1][0]+element.arr[list_elem][elem-1][1],element.arr[list_elem][elem][0]+element.arr[list_elem][elem][1])}}/>
                                                                </>
                                                            )
                                                            
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                        {element.arr[list_elem].length>0 && <span dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text.slice(element.arr[list_elem][element.arr[list_elem].length-1][0]+element.arr[list_elem][element.arr[list_elem].length-1][1],el[element.obj][list_elem].text.length)} }/>}
                                        {element.arr[list_elem].length==0 && <span dangerouslySetInnerHTML={{__html: el[element.obj][list_elem].text} }/>}
                                        
                                                    </li>
                                                )
                                            }
                                                
                                        })}
                                        
                                        </ul>
                                        
                                        
                                        :
                                        <div>
                                        {element.arr.length>0 && <span dangerouslySetInnerHTML={{__html: el[element.obj].slice(0,element.arr[0][0])}}/>}
                                        {
                                            number.map(elem=>{
                                                if(element.arr[elem]!=undefined){
                                                    if(elem==0){
                                                        if(element.arr[elem].length==2){
                                                            return(
                                                                <>
                                                                    <span className={styles.bold} dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[elem][0],element.arr[elem][0]+element.arr[elem][1])}}/>
                                                                    {element.arr[elem+1]!=undefined && <span dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[elem][0]+element.arr[elem][1],element.arr[elem+1][0])}}/>}
                                                                </>
                                                            )
                                                            
                                                        }
                                                        else{
                                                            return(
                                                                <>
                                                                    <a className={styles.link} href={element.arr[elem][2]} dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[elem][0],element.arr[elem][0]+element.arr[elem][1])}}/>
                                                                    {element.arr[elem+1]!=undefined && <span dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[elem][0]+element.arr[elem][1],element.arr[elem+1][0])}}/>}
                                                                </>
                                                            )
                                                            
                                                        }
                                                    }
                                                    if(element.arr[elem-1][0]+element.arr[elem-1][1]<=element.arr[elem][0]){
                                                        if(element.arr[elem].length==2){
                                                            return(
                                                                <>
                                                                    <span className={styles.bold} dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[elem][0],element.arr[elem][0]+element.arr[elem][1])}}/>
                                                                    {element.arr[elem+1]!=undefined && <span dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[elem][0]+element.arr[elem][1],element.arr[elem+1][0])}}/>}
                                                                </>
                                                            )
                                                            
                                                        }
                                                        else{
                                                            return(
                                                                <>
                                                                    <a className={styles.link} href={element.arr[elem][2]} dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[elem][0],element.arr[elem][0]+element.arr[elem][1])}}/>
                                                                    {element.arr[elem+1]!=undefined && <span dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[elem][0]+element.arr[elem][1],element.arr[elem+1][0])}}/>}
                                                                </>
                                                            )
                                                            
                                                        }
                                                    }
                                                    else{
                                                        if(element.arr[elem].length==2){
                                                            return(
                                                                <>
                                                                    <span dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[elem-1][0]+element.arr[elem-1][1],element.arr[elem][0]+element.arr[elem][1])}}/>
                                                                </>
                                                            )
                                                            
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                        {element.arr.length>0 && <span dangerouslySetInnerHTML={{__html: el[element.obj].slice(element.arr[element.arr.length-1][0]+element.arr[element.arr.length-1][1],el[element.obj].length)} }/>}
                                        {element.arr.length==0 && <span dangerouslySetInnerHTML={{__html: el[element.obj]}}/>}
                                        
                                    </div>}</div>
                                )
                            })
                        }
                        {el.img && <div className={styles.div_img}><img id={`img_${el.id}`} src={`/static/posts/${el.img.link}`} onLoad={()=>imgStyle(el.id, el.img.link)} className={state[el.id] ? styles.active : styles.img} onClick={()=>Click(el.id)}/><p className={state[el.id] ? styles.anot_active : styles.anot}>{el.img.anotation}</p></div>}
                        </div>
                    )
                })}
            </div>
        {/* } */}
        </>
    )
}

export default Post