import React, { useState } from "react";
import styles from './Invitation.module.css'
import {Link} from "react-router-dom";

function Invitation(props){
    const [state,setState]=useState(false)


    return(
        <>
            <div className={styles.title}>Запрошуємо на навчання!</div>
            <div className={styles.main}>
                <div>Шановний абітурієнте, долучайтесь до інтелектуальної спільноти Львівської політехніки!</div>
                <div>
                    Кафедра прикладної математики Національного університету «Львівська політехніка» та глобальна ІТ-компанія SoftServe запрошують Вас на навчання за освітньо-науковими програмами спеціальності 113 «Прикладна математика»:
                    <ul>
                        <li>"Прикладна математика та інформатика"</li>
                        <li>"Фінансовий інжиніринг"</li>
                    </ul>
                </div>
                <img className={state ? styles.active : styles.img} onClick={()=>setState(!state)} src="static/img/photo_2023-06-08_10-50-34.jpg"/>
                <div className={state ? styles.info : styles.hidden}>Викладачі кафедри "Прикладної математики"</div>
                <div>
                Повідомляємо, що <b>кафедра "Прикладної математики"</b> співпрацює із глобальною ІТ-компанією <b>SoftServe</b> в рамках програми дуальної освіти. Також на кафедрі прикладної математики діє програма подвійних дипломів магістра, відповідно до якої студенти мають можливість навчатися в Італії <b><a href='https://www.univaq.it/en/index.php'>University of L’Aquila</a></b> та одночасно з українським дипломом магістра отримати італійський диплом магістра із "Математичної інженерії".
                </div>
                <div>Бути випускником кафедри "Прикладної математики" - означає досягти успіху. Випускник нашої кафедри отримує можливість стати науковцем, працівником передових IT компаній та навіть засновником своєї компанії. Детальніше про успіхи наших випускників можна дізнатися за <Link to='/graduates'>посиланням</Link></div>
                <div>Зі всіма важливими новинами про життя кафедри Ви можете стежити на: <a href='https://t.me/amath_lp_edu_ua'>офіційному телеграм-каналі "Прикладна математика"</a>. Колектив кафедри прикладної математики буде радий Вас навчати на наших бакалаврських, а в подальшому магістерських програмах!</div>
                <div>Детально про умови вступу читайте <a href='https://lpnu.ua/pryimalna-komisiia/pravyla-pryiomu'>тут</a></div>
            </div>
        </>
    )
}

export default Invitation