import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import styles from './PostCard.module.css'
import backendRequest from '../../axios';


function PostCard({info}) {

    const parsedPost = JSON.parse(info.description);
    const firstParagraph = parsedPost.filter(p => 'text' in p)[0].text;
    const imgInfo = parsedPost.filter(p => 'img' in p);

    useEffect(() => { 
        imgInfo[0] && backendRequest.get("/download/" + imgInfo[0].img.link, {responseType:"blob"})
            .then(res => {
                var img = document.getElementById("post_" + info.id);
                var fr = new FileReader();
                fr.onload = () => {
                    img.src = fr.result;
                }
                fr.readAsDataURL(res.data);
            })
    }, [imgInfo])

    return (
        <Link to={"/posts/" + info.id} className={styles.card}>
            {imgInfo[0] && <img id={"post_" + info.id} className={styles.image} alt=""/>}
            <p className={styles.title}>{info.title}</p>
        </Link>
    );
}

export default PostCard;