import React from 'react'
import data from './data.json'
import styles from './Labs.module.css'

function Labs(props){

    return(
        <>
            <div className={styles.title}>Навчальні лабораторії</div>
            <div className={styles.main}>
                {data.map((el,index)=>{
                    return(
                        <div key={index} className={styles.bodytext}>
                        {el.text}
                        {el.list && <ul>{el.list.map((element,id)=><li key={id}>{element}</li>)}</ul>}
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Labs