import axios from "axios";
import config from "./appconfig.json"

const app_path = config.production ? 
  config.api_path :
  "http://localhost:" + config.api_port;

const backendRequest = axios.create({
  baseURL: app_path + "/api/", 
  withCredentials: true,
});

export default backendRequest