import React, { useContext, useEffect, useState } from "react";
import styles from "../AdminMain.module.css";
import backendRequest from "../../../../axios";
import { AuthContext } from "../../../../context/userContext";
import { useNavigate } from "react-router-dom";

function AdminUserFirstTimeForm(){
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    if (currentUser && !currentUser.firstLogin) navigate('/admin/users');

    const [errorMessage, setErrorMessage] = useState("Не вдалось зареєструвати користувача. Проблеми із сервером...");
    const [baddataDisplay, setBaddataDisplay] = useState("none");
    const [successDisplay, setSuccessDisplay] = useState("none");
    const [formInputs, setFormInputs] = useState({
        login: "",
        display_name:"",
        pwd:""
    })

    const inputsHandle = (e) => {
        setFormInputs(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const addUserHandle = async (e) => {
        e.preventDefault();
        if(formInputs.login == "")
            formInputs.login = currentUser.login;
        if(formInputs.email == "")
            formInputs.email = currentUser.email;

        await backendRequest
            .put("/users/" + currentUser.id, formInputs)
            .then(res => {
                let form = document.getElementById("add_user_form");
                let elements = form.elements;
                for (let i = 0, len = elements.length; i < len; ++i)
                    elements[i].readOnly = true;
                let button = document.getElementById("submit_button");
                button.disabled = true;
                const user = JSON.parse(localStorage.getItem("user"));
                user.display_name = formInputs.display_name;
                user.firstLogin = false;
                localStorage.setItem("user", JSON.stringify(user));
                setSuccessDisplay("block");
                setBaddataDisplay("none");
                setTimeout( () => navigate('/admin'), 500);
            })
            .catch(err => {
                console.log(err);
                setBaddataDisplay("block");
                setErrorMessage('response' in err ? err.response.data : "Не вдалось оновити користувача. Проблеми із сервером...");
            })
        }

    return(
        <div className={styles.container}>
            <div className={styles.users_title}>Перший вхід у систему - примусова зміна імені користувача та паролю</div>
            <div className={styles.content + ' ' + styles.user_content}>
                <form id="add_user_form" className={styles.add_user_form}>
                    <label>Ім'я користувача</label>
                    <input type="text" placeholder="Нове ім'я користувача" name='display_name' onChange={inputsHandle}/>
                    <label>Пароль</label>
                    <input type="password" placeholder="Новий пароль" name='pwd' onChange={inputsHandle}/>
                    <p className={styles.success} style={{display: successDisplay}}>Успішно змінено!</p>
                    <p className={styles.warning} style={{display: baddataDisplay}}>{errorMessage}</p>
                    <button id="submit_button" className={styles.submit_button} onClick={addUserHandle}>Оновити інформацію</button>
                </form>
            </div>
        </div>
    )
}

export default AdminUserFirstTimeForm;