import React from "react";
import styles from './Graduates.module.css'


function Graduates(props){

    const graduates=[
        {photo:'kusnir',name:'Роман Кушнір',graduate:'Випускник 1976 року',info:'Доктор фізико-математичних наук та професор за спеціальністю «механіка деформівного твердого тіла». Обраний дійсним членом Наукового товариства імені Шевченка, членом-кореспондентом НАН України та академіком НАН України за спеціальністю «математичні проблеми механіки» по Відділенню математики НАН України. Заслужений діяч науки і техніки України. Лауреат Державної премії України в галузі науки і техніки та премій ім. М. О. Лаврентьєва та імені М.М. Крилова НАН України. З 2003 р. директор Інституту прикладних проблем механіки і математики ім. Я.С. Підстригача НАН України.'},
        {photo:'cutniv',name:'Мирослав Кутнів',graduate:'Випускник 1979 року',info:'Доктор фізико-математичних наук за спеціальністю «Обчислювальна математика», професор кафедри прикладної математики. Науковий керівник 3 кандидатських дисертацій. Місце роботи: професор кафедри математичного моделювання Ряшівської політехніки ім. Ігнатія Лукасевича (Польща).'},
        {photo:'boyko',name:'Оксана Бойко',graduate:'Випускниця 1997 року',info:'Доктор технічних наук, завідувач кафедри медичної інформатики Львівського національного медичного університету імені Данила Галицького. Є автором понад 200 наукових праць, зокрема 25 статей у виданнях,включених до міжнародних наукометричних баз Scopus та Web of Science, 5 навчальних посібників для студентів вищих медичних навчальних закладів, 2 монографій, 7 патентів.'},
        {photo:'beluh',name:'Матвій Бєлих',graduate:'Випускник 1997 року',info:'Консультант з інформаційних технологій у компанії Unified Technologies, Маямі, США. Працював в Україні, Канаді та США на технічних та керівних посадах.'},
        {photo:'scoropad',name:'Андрій Скоропад',graduate:'Випускник 2000 року',info:'Співзасновник та виконавчий директор (Chief Executive Officer) компанії Perfectial (з 2021 Perfectial powered by Avenga) – міжнародної ІТ-компанії з розробки програмного забезпечення, тестування та впровадження програмних продуктів. Більшість спеціалістів компанії працюють у трьох офісах у Львові, Івано-Франківську та Києві. Серед клієнтів компанії Wizzair, Mirantis, Clickatell та Roku.'},
        {photo:'klym',name:'Зореслава Клим',graduate:'Випускниця 2001 року',info:'Віце-президент у компанії SoftServe (Associate Vice President of Delivery). За 20 років здобула успішний досвід роботи у декількох ІТ компаніях (Conscensia, Softserve) на технічних та керівних посадах. Розпочинала професійний шлях як тестувальник програмного забезпечення, також працювала проектним менеджером, директором департаменту, співпрацюючи із компаніями-лідерами на ринку інформаційних технологій.'},
        {photo:'chubay',name:'Чубай Олександр',graduate:'Випускник 2002 року',info:'Технічний директор (Chief Technology Officer) у компанії SoftServe. Керує технологічною стратегією та інноваційною розробкою, створюючи стійкі конкурентні переваги, завдяки постійним технологічним інноваціям. Має понад двадцятирічний стаж керівної роботи у сфері ІТ. '},
        {photo:'cohut',name:'Іван Когут',graduate:'Випускник 2003 року',info:"Співзасновник та технічний директор (Chief Technology Officer) компанії Perfectial (з 2021 Perfectial powered by Avenga). Компанія Perfectial залучена та виступає спонсором п'яти освітніх програм ІТ Кластеру, зокрема: IoT, Системи Штучного Інтелекту ( Львівська Політехніка), Data Science&Intelligent Systems, Системний Аналіз та Комп'ютерні Науки. Компанія щорічно приймає на роботу випускників та студентів кафедри прикладної математики."},
        {photo:'demkiv',name:'Любомир Демків',graduate:'Випускник 2003 року',info:'Доктор технічних наук за спеціальністю cистеми та процеси керування, професор кафедри інформаційні системи та мережі, Robotics Practice Leader у SoftServe.'},
        {photo:'slipchenko',name:'Ірина Сліпченко',graduate:'Випускниця 2005 року',info:'Віце президент компанії SoftServe з ділових питань (Vice President of CIS Business Affairs). Живе у San Francisco, California.'},
        {photo:'voytovych',name:'Тарас Войтович',graduate:'Випускник 2006 року',info:'Засновник компанії Teamvoy – аутсорсингової компанії в Україні, що спеціалізується на розробці програмного забезпечення та консультаційних послугах. Teamvoy використовує найкращі практики та новітні стандарти, щоб виробляти високоякісне та надійне програмне забезпечення.'},
        {photo:'lozynskiy',name:'Володимир Лозинський',graduate:'Випускник 2010 року',info:'Директор Департаменту ризиків у Royal Bank of Canada.'},
        {photo:'charkovskiy',name:'Ігор Чарковський',graduate:'Випускник 2010 року',info:'Співзасновник та виконавчий директор ігрової студії CharStudio – креативної розважальної компанії, що займається створенням та випуском власних казуальних ігор.'},
        {photo:'danylo',name:'Ольга Данило',graduate:'Випускниця 2012 року',info:'Кандидат технічних наук, провідний дослідник у International Institute for Applied Systems Analysis, Лаксенбург, Австрія та провідний дослідник даних у Crowther Laboratory, Technical University of Zurich, Цюріх, Швейцарія. Викладає у Віденському технічному університеті, Віденському університеті економіки та бізнесу та Технічному університеті Цюриха, читала авторські курси лекцій в університетах Італії, Бразилії та Польщі.'},
        {photo:'mykytyn',name:'Назар Микитин',graduate:'Випускник 2012 року',info:"Співзасновник та виконавчий директор компанії RedTag, яка займається розробкою програмного забезпечення для систем бухгалтерського обліку, роздрібним програмним забезпеченням, автоматизацією бізнес-процесів, фоновою архітектурою та її розробкою, інтеграцією систем. Майже кожен дев'ятий працівник компанії є випускником кафедри ПМ."}
    ]

    let splitgrad=[]
    return(
        <>
        <div className={styles.title}>Відомі випускники</div>
            {graduates.map((grad,index)=>{
                return(
                    <div key={index} className={styles.paragraph}>
                        <div className={styles.photo}><img src={`../static/graduates/${grad.photo}.jpg`}></img></div>
                        <div className={styles.main}>
                            <div>
                                <div className={styles.name}>{grad.name}</div>
                                {
                                    window.innerWidth>740 ? 
                                    <div className={styles.name}>{grad.graduate}</div>
                                    :
                                    <>
                                    <div style={{display:'none'}}>{splitgrad=grad.graduate.split(' ')}</div>
                                    <div className={styles.graduate}>{splitgrad[0]}</div>
                                    <div className={styles.graduate}>{splitgrad[1]} {splitgrad[2]}</div>
                                    </>
                                }
                            </div>
                            <div className={styles.info}>{grad.info}</div>
                        </div>
                            
                            
                    </div>
                )
            })}
        </>
    )
}

export default Graduates