import React, { createContext, useEffect, useState } from "react"
import backendRequest from "../axios";

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user") || null));

    const login = async (inputs) => {
        const res = await backendRequest.post("/users/login", inputs)
            .catch(err => {throw err});
        setCurrentUser(res.data);
    }

    const logout = async (inputs) => {
        const res = await backendRequest.post("/users/logout", inputs);
        setCurrentUser(null);
    }

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser]);

    return (
        <AuthContext.Provider value={{currentUser, login, logout}}>{children}</AuthContext.Provider>
    )
}