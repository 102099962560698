import React, { useEffect, useRef } from 'react';
import { useLocation, Link, useNavigate} from 'react-router-dom';
import styles from "./TeacherPage.module.css"
import parse from 'html-react-parser'
import backendRequest from "../../axios"
import { useQuery } from "@tanstack/react-query"
import { ArrowForward, EmailRounded } from '@material-ui/icons';
import LoadingSpinner from '../Spinner';
import TeacherPageList from './TeacherPageList';
import TeacherPageDoubList from './TeacherPageDoubList';
import Page404 from '../errorPages/Page404';
import loadImage from '../../loadImage';

function TeacherPage() {
    const { state, pathname } = useLocation()
    const parsedPath = pathname.split('/');
    const id = state ? state.id : parsedPath[parsedPath.length - 1];
    const ref = useRef();
    const navigate = useNavigate();

    const positions = [
        {id:1, title:"завідувач кафедрою"},
        {id:2, title:"заступник завідувача кафедрою"},
        {id:3, title:"професор"},
        {id:4, title:"доцент"},
        {id:5, title:"старший викладач"},
        {id:6, title:"асистент"},
    ]

    useEffect(() => {
        async function fetchImage() {
            await loadImage("big-logo", data[0].big_img_path);
        }

        if (ref.current && ref.current.complete) {
            fetchImage(ref.current);
          }
    })

    const { isLoading, error, data } = useQuery(["teachersInfo"], () =>
        backendRequest.get(`/teachers/${id}/info`).then(async(res) => {
            return res.data
    }));

    return (
        <div>
            <div className={styles.page_title}>
                <Link to="/staff/teachers">Викладачі кафедри "Прикладна математика" </Link>
            </div>
            {   
                error
                ? error.response.status = 404 ? <Page404/> : "Щось пішло не так!"
                : isLoading
                ? <LoadingSpinner/>
                :
                <>
                    <div className={styles.staff_title}>
                        {data ? data[0].full_name : "Завантаження..."} 
                    </div>
                    <div className={styles.container}>
                        <div className={styles.main_frame}>
                            <div className={styles.img_wrapper}>
                                <img id="big-logo" ref={ref} alt={data[0].full_name}/>
                            </div>
                            <div>Посада: {positions.filter(x => x.id === data[0].position_id)[0].title}</div>
                            <div>e-mail: <a className={styles.a} href={"mailto:" + data[0].email}>{data[0].email}</a></div>
                        </div>
                        <div className={styles.content}>
                            {
                                data[0].description.map(block => block.desc_type === "text"
                                ? <div key={block.id}><div className={styles.text_block_title}>{block.desc_title}</div><p>{parse(block.desc_value)}</p></div>
                                : block.desc_type === "list"
                                ? <TeacherPageList key={block.id} list={block.desc_value} title= {block.desc_title}/>
                                : block.desc_type === "doub-list"
                                ? <TeacherPageDoubList key={block.id} list={block.desc_value} title= {block.desc_title}/>
                                : "")
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default TeacherPage;