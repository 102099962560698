import React, {useState } from "react";
import styles from './Subjects.module.css'
import { useQuery } from "@tanstack/react-query";
import backendRequest from "../../../../axios";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import LoadingSpinner from "../../../Spinner";

function Subject(props){

    const addState=[]

    const { isLoading, error, data:subjects, refetch } = useQuery(["subjects"], () =>
        backendRequest.get("/subjects").then((res) => {
            for(let i=0;i<res.data.length;i++){
                addState.push(false)
            }
            const sub = res.data.sort((a, b) => {
                const titleA = a.title.toUpperCase();
                const titleB = b.title.toUpperCase();
              
                if (titleA < titleB) {
                  return -1;
                }
                if (titleA > titleB) {
                  return 1;
                }
                return 0;
            });
            return sub
      }));

      

    const[state,setState]=useState(addState)

    const addSubject=async ()=>{
        const arr=state.slice()
        arr.push(true)
        setState(arr)
        await backendRequest.post('/subjects',{title:'Дисципліна за вибором',description:'"Дізнатися анотацію курсу буде можливо після вибору дисципліни"'})
        refetch()
    }

    const Save=async (id)=>{

        const regex = /\p{L}/u
        const newTitle=document.getElementById(`name_${id}`).value
        const newAnotation=document.getElementById(`anotation_${id}`).value
        if(regex.test(newAnotation)){
            let arr=state.slice()
            arr[id]=false
            setState(arr)
            await backendRequest.put('/subjects/' + id,{title:newTitle,description:newAnotation})
            refetch()
        }
        else{
            const result=window.confirm('Якщо Ви продовжите дію, то у предмета не буде анотації і у карті курсів його можна буде використовувати лише як предмет загальної підготовки. Бажаєте продовжити?')
            if(result){
                await backendRequest.put('/subjects/' + id,{title:newTitle,description:' '})
                refetch()
                let arr=state.slice()
                arr[id]=false
                setState(arr)
            }
        }
    }
    const save=(id)=>{
        let arr=state.slice()
        arr[id]=true
        setState(arr)
    }

    return(
        error
                ? error.response.data
                : isLoading
                ? <LoadingSpinner/>
                :
        <div className={styles.table}>
            <div className={styles.head}>
                Предмети кафедри Прикладної математики
            </div>
            {subjects.map(subj=>{
                return(
                    <div className={styles.list}>
                        <div className={styles.list_elem}>
                            <div>{subj.title}</div>
                            {!state[subj.id] && <button onClick={()=>save(subj.id)}><EditIcon/> редагувати</button>}
                        </div>
                        <div className={state[subj.id] ? styles.modify : styles.hidden}>
                            <div>Назва предмету: <input id={`name_${subj.id}`} type='text' defaultValue={subj.title}/></div>
                            <div>Анотація предмету:<textarea id={`anotation_${subj.id}`} defaultValue={subj.description}/></div>
                            <button onClick={()=>Save(subj.id)}><CheckCircleRoundedIcon/> Зберегти</button>
                        </div>
                    </div>
                )
            })}
            <div className={styles.footer}><button className={styles.add} onClick={()=>addSubject()}><AddCircleRoundedIcon/> Додати предмет</button></div>
        </div>
    )
}

export default Subject